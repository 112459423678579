import React from "react"

import { ReactComponent as DataHolder } from "./assets/dataHolder.svg"

import cls from "./footer.module.scss"
import { Logo } from "@/shared/ui/Logo/Logo"
import { BusinessInfo } from "./BusinessInfo/BusinessInfo"
import { classNames } from "@/shared/lib/utils/classNames"

const Footer = ({ className }) => {
    return (
        <footer
            className={classNames(cls.Footer, [className])}>
            <div className={cls.footerInner}>
                <p className={cls.rights}>
                    © 2024 Dataholder. All rights reserved
                </p>
                <Logo className={cls.footerLogo} />
                <BusinessInfo />
            </div>
        </footer>
    )
}
export default Footer
