import React from "react"
import { ReactComponent as ArrowIcon } from "@/shared/assets/arrowRightIcon.svg"
import dashboards from "./assets/dashboards.webp"
import { BlogCard } from "@/entities/BlogCard/BlogCard"
import bigdataimage from "../BlogPostBigData/assets/bigdata.webp"
import masterimage from "../BlogPostMaster/assets/master.webp"
import cls from "./dashboards.module.scss"
import { useNavigate } from "react-router-dom"

export const BlogPostDasboards = () => {
    const navigate = useNavigate()
    return (
        <div className={cls.content}>
            <div className={cls.title}>
                <h2>
                    Boost Productivity with Data Holder
                    Dashboards
                </h2>
                <div className={cls.date}>
                    <span>Posted on</span>
                    <span className={cls.num}>
                        October 26, 2023
                    </span>
                </div>
            </div>
            <div className={cls.withImage}>
                <img
                    className={cls.image}
                    src={dashboards}
                />
                <div className={cls.text}>
                    <p>
                        Productivity is a key factor in
                        modern business success, and one of
                        the important tools that contributes
                        to its enhancement is dashboards.
                    </p>
                    <p>
                        Dashboards are intuitive interactive
                        panels featuring graphs, charts, and
                        metrics, allowing for quick and
                        efficient monitoring of crucial
                        indicators and informed
                        decision-making.{" "}
                    </p>
                    <p>
                        It is through dashboards that
                        companies and professionals can
                        promptly respond to environmental
                        changes, plan actions, and improve
                        processes.
                    </p>
                </div>
            </div>
            <p>
                There are numerous benefits to using
                dashboards. They help reduce time spent on
                data analysis, provide a visual
                representation of complex information flows,
                enhance communication among project
                participants, and facilitate informed
                decision-making based on facts and figures.
            </p>
            <p>
                For maximum productivity and efficiency in
                using dashboards, companies can leverage the
                innovative tool Data Holder. The Data Holder
                website offers convenient features for
                creating personalized dashboards tailored to
                specific company needs. With Data Holder,
                employees can swiftly and conveniently track
                metrics, analyze data, and make informed
                decisions to achieve business goals. Data
                Holder becomes an indispensable assistant in
                boosting team productivity and efficiency,
                ensuring transparency and manageability of
                processes.
            </p>
            <div className={cls.pagination}>
                <div
                    onClick={() =>
                        navigate("/companyblog/bigdata")
                    }
                    className={cls.prevPost}>
                    <ArrowIcon className={cls.prevArrow} />
                    <p>Previous post</p>
                </div>
                <div
                    onClick={() =>
                        navigate("/companyblog/masterpost")
                    }
                    className={cls.nextPost}>
                    <p>Next post</p>
                    <ArrowIcon className={cls.nextArrow} />
                </div>
            </div>

            <div className={cls.posts}>
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/bigdata")
                    }
                    image={bigdataimage}
                    title={
                        "Revolutionizing Big Data: Data Holder's Advanced Visualizati..."
                    }
                    description={
                        "With advancements in technology and the exponential growth of data volumes, companies have increasingly faced the.."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/masterpost")
                    }
                    title={
                        "Master IT Data Management with Data Holder"
                    }
                    image={masterimage}
                    description={
                        "How you can set personal boundaries to reduce stress and..."
                    }
                    date={"October 26, 2023"}
                />
            </div>
        </div>
    )
}
