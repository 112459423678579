import React from "react"

import cls from "./paymentstatus.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import Button from "@/shared/ui/Button/Button"

export const PaymentStatus = ({
    status,
    payInfo,
    onClick,
}) => {
    const render = (status) => {
        if (status === "Success") {
            return (
                <div
                    className={classNames(cls.ContactForm, [
                        cls.success,
                    ])}>
                    <svg
                        width='52'
                        height='52'
                        viewBox='0 0 52 52'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            opacity='0.15'
                            d='M26 3C13.3031 3 3 13.3031 3 26C3 38.6969 13.3031 49 26 49C38.6969 49 49 38.6969 49 26C49 13.3031 38.6969 3 26 3Z'
                            stroke='#0CF18A'
                            strokeWidth='6'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M26 6C14.96 6 6 14.96 6 26C6 37.04 14.96 46 26 46C37.04 46 46 37.04 46 26C46 14.96 37.04 6 26 6ZM20.58 34.58L13.4 27.4C12.62 26.62 12.62 25.36 13.4 24.58C14.18 23.8 15.44 23.8 16.22 24.58L22 30.34L35.76 16.58C36.54 15.8 37.8 15.8 38.58 16.58C39.36 17.36 39.36 18.62 38.58 19.4L23.4 34.58C22.64 35.36 21.36 35.36 20.58 34.58Z'
                            fill='#0CF18A'
                        />
                    </svg>
                    <h3>Payment Successful</h3>
                    <div>
                        <p>
                            The invite was sent to the{" "}
                            <b>{payInfo.email}</b>
                        </p>
                        <p>
                            ID payment:{" "}
                            <b>{payInfo.PaymentID}</b>
                        </p>
                    </div>

                    <Button onClick={onClick}>
                        Go home
                    </Button>
                </div>
            )
        } else if (status === "Failed") {
            return (
                <div
                    className={classNames(cls.ContactForm, [
                        cls.success,
                    ])}>
                    <svg
                        width='52'
                        height='52'
                        viewBox='0 0 52 52'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            opacity='0.15'
                            d='M26 3C13.3031 3 3 13.3031 3 26C3 38.6969 13.3031 49 26 49C38.6969 49 49 38.6969 49 26C49 13.3031 38.6969 3 26 3Z'
                            stroke='#FF0000'
                            strokeWidth='6'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M26 6C14.96 6 6 14.96 6 26C6 37.04 14.96 46 26 46C37.04 46 46 37.04 46 26C46 14.96 37.04 6 26 6Z'
                            fill='#FF0000'
                        />
                        <path
                            d='M17 17L35 35M35 17L17 35'
                            stroke='white'
                            strokeWidth='5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>

                    <h3>Something went wrong!</h3>
                    <p>
                        Thank you for your payment. We will
                        be in contact with more details
                        shortly
                    </p>
                    <Button
                        variant='outlined'
                        onClick={onClick}>
                        Try again
                    </Button>
                </div>
            )
        } else if (status === "Waiting") {
            return (
                <div
                    className={classNames(cls.ContactForm, [
                        cls.success,
                    ])}>
                    <svg
                        width='52'
                        height='52'
                        viewBox='0 0 52 52'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            opacity='0.15'
                            d='M26 3C13.3031 3 3 13.3031 3 26C3 38.6969 13.3031 49 26 49C38.6969 49 49 38.6969 49 26C49 13.3031 38.6969 3 26 3Z'
                            stroke='#0CF18A'
                            strokeWidth='6'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M26 6C14.96 6 6 14.96 6 26C6 37.04 14.96 46 26 46C37.04 46 46 37.04 46 26C46 14.96 37.04 6 26 6ZM20.58 34.58L13.4 27.4C12.62 26.62 12.62 25.36 13.4 24.58C14.18 23.8 15.44 23.8 16.22 24.58L22 30.34L35.76 16.58C36.54 15.8 37.8 15.8 38.58 16.58C39.36 17.36 39.36 18.62 38.58 19.4L23.4 34.58C22.64 35.36 21.36 35.36 20.58 34.58Z'
                            fill='#0CF18A'
                        />
                    </svg>
                    <h3>message sent</h3>
                    <div>
                        <p>
                            Please follow instructions sent
                            on your email{" "}
                            <b>{payInfo.email}</b>
                        </p>
                    </div>

                    <Button onClick={onClick}>
                        Go home
                    </Button>
                </div>
            )
        }
    }
    return render(status)
}
