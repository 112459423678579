import React from "react"

import cls from "./ourteam.module.scss"
import Dmitrii from "@/shared/assets/portraits/Dmitrii.webp"
import Artem from "@/shared/assets/portraits/Artem.webp"
import Evgeniy from "@/shared/assets/portraits/Evgeniy.webp"
import Rim from "@/shared/assets/portraits/Rim.webp"
import Ruslan from "@/shared/assets/portraits/Ruslan.webp"
import Vadim from "@/shared/assets/portraits/Vadim.webp"
import Marquee from "react-fast-marquee"
import useResize from "@/shared/lib/hooks/useResize"
import { useTranslation } from "react-i18next"

export const OurTeam = () => {
    const { t } = useTranslation()
    const data = [
        {
            name: "Dmitrii Vysochin",
            role: t("aboutPage.ceo"),
            image: Dmitrii,
        },
        {
            name: "Artem Gorodetskii",
            role: t("aboutPage.cpo"),
            image: Artem,
        },
        {
            name: "Evgeniy Volokhov",
            role: t("aboutPage.designer"),
            image: Evgeniy,
        },
        {
            name: "Vadim Sigutin",
            role: t("aboutPage.frontend"),
            image: Vadim,
        },
        {
            name: "Ruslan Khisamov",
            role: t("aboutPage.backend"),
            image: Ruslan,
        },
        {
            name: "Rim Khairullin",
            role: t("aboutPage.rfrontend"),
            image: Rim,
        },
    ]

    const windowWidth = useResize()

    const teamRender = () => {
        if (windowWidth <= 1024) {
            return (
                <Marquee
                    speed={100}
                    className={cls.teamMarquee}>
                    <div className={cls.teammateContainer}>
                        {data.map((item) => (
                            <div className={cls.teammate}>
                                <img
                                    src={item.image}
                                    alt={item.name}
                                />
                                <h4>{item.name}</h4>
                                <p>{item.role}</p>
                            </div>
                        ))}
                    </div>
                </Marquee>
            )
        } else {
            return (
                <div className={cls.teammateContainer}>
                    {data.map((item) => (
                        <div className={cls.teammate}>
                            <img
                                src={item.image}
                                alt={item.name}
                                loading='lazy'
                            />
                            <h4>{item.name}</h4>
                            <p>{item.role}</p>
                        </div>
                    ))}
                </div>
            )
        }
    }
    return (
        <div className={cls.OurTeam}>
            <h2>{t("aboutPage.ourTeam")}</h2>
            {teamRender()}
        </div>
    )
}
