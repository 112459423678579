import React, { useState } from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import { ReactComponent as Dots } from "@/shared/assets/dots.svg"
import dataTools from "@/shared/assets/images/dataTools.svg"
import customData from "@/shared/assets/images/customData.svg"
import collaborate from "@/shared/assets/images/collaborate.svg"
import stayInformed from "@/shared/assets/images/stayInformed.svg"
import Pagination from "@/features/Pagination/Pagination"
import { Frame } from "@/shared/ui/Frame/Frame"
import Button from "@/shared/ui/Button/Button"
import { useTranslation } from "react-i18next"

import cls from "./featuressection.module.scss"

export const FeaturesSection = ({ id, className }) => {
    const [page, setPage] = useState(1)
    const TOTAL_PAGES = 4

    const { t } = useTranslation()

    const content = [
        {
            title: t(
                "aboutBlock.features.content.powerfulTools.title"
            ),
            content: t(
                "aboutBlock.features.content.powerfulTools.content"
            ),
            imageSrc: dataTools,
        },
        {
            title: t(
                "aboutBlock.features.content.customData.title"
            ),
            content: t(
                "aboutBlock.features.content.customData.content"
            ),
            imageSrc: customData,
        },
        {
            title: t(
                "aboutBlock.features.content.collaborate.title"
            ),
            content: t(
                "aboutBlock.features.content.collaborate.content"
            ),
            imageSrc: collaborate,
        },
        {
            title: t(
                "aboutBlock.features.content.stayInformed.title"
            ),
            content: t(
                "aboutBlock.features.content.stayInformed.content"
            ),
            imageSrc: stayInformed,
        },
    ]

    const onClickLeft = () => {
        if (page === 1) {
            setPage(4)
        } else {
            setPage(page - 1)
        }
    }

    const onClickRight = () => {
        if (page === TOTAL_PAGES) {
            setPage(1)
        } else {
            setPage(page + 1)
        }
    }
    return (
        <div
            id={id}
            className={classNames(cls.FeaturesSection, [
                className,
            ])}>
            <div className={cls.textContainer}>
                <p className={cls.title}>
                    {t("aboutBlock.features.title1")}
                </p>
                <div className={cls.sliderContainer}>
                    {content.map((item, index) => (
                        <>
                            <h2
                                style={{
                                    display:
                                        index !== page - 1
                                            ? "none"
                                            : "block",
                                }}>
                                {item.title}
                            </h2>
                            <p
                                style={{
                                    display:
                                        index !== page - 1
                                            ? "none"
                                            : "block",
                                }}>
                                {item.content}
                            </p>
                        </>
                    ))}
                </div>
                <a href='/client/register'>
                    <Button
                        size='medium'
                        link={false}
                        className={cls.readMoreButton}>
                        {t("homeBlock.button")}
                    </Button>
                </a>
                <div className={cls.actionsContainer}>
                    <Pagination
                        currentPage={page}
                        totalPages={TOTAL_PAGES}
                        onClickLeft={() => onClickLeft()}
                        onClickRight={() => onClickRight()}
                    />
                </div>
                <Dots className={cls.dots} />
            </div>
            <div className={cls.circle} />
            <div className={cls.circleViolet} />
            <div className={cls.frameContainer}>
                <Frame className={cls.frame}>
                    {content.map((item, index) => (
                        <img
                            className={classNames(
                                cls.img,
                                [],
                                {
                                    [cls.dif]: index !== 0,
                                }
                            )}
                            style={{
                                display:
                                    index !== page - 1
                                        ? "none"
                                        : "block",
                            }}
                            src={item.imageSrc}
                            alt='feature'
                            loading='lazy'
                        />
                    ))}
                </Frame>
            </div>
        </div>
    )
}
