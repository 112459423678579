import React from "react"
import { ReactComponent as Illustration } from "./illustration/farewellIllustration.svg"
import footage from "@/shared/assets/dataHolderFootage.webp"
import Button from "@/shared/ui/Button/Button"
import { Frame } from "@/shared/ui/Frame/Frame"
import cls from "./farewell.module.scss"
import useResize from "@/shared/lib/hooks/useResize"
import { useTranslation } from "react-i18next"

const FarewellBlock = ({ id }) => {
    const windowWidth = useResize()
    const { t } = useTranslation()
    return (
        <div
            id={id}
            className={cls.container}>
            {windowWidth > 768 ? (
                <Illustration
                    className={cls.Illustration}
                />
            ) : (
                <></>
            )}

            <div className={cls.FarewellBlock}>
                <div className={cls.headline}>
                    <div className={cls.title}>
                        <h2>
                            {t("farewellBlock.getText")}
                        </h2>
                        <p>
                            {t("farewellBlock.description")}
                        </p>
                    </div>
                    <a href='/client/register'>
                        <Button
                            className={cls.getButton}
                            link={false}
                            size='large'>
                            {t("farewellBlock.buttonText")}
                        </Button>
                    </a>
                </div>
                <Frame className={cls.frame}>
                    <img
                        className={cls.image}
                        src={footage}
                        alt='footage'
                    />
                </Frame>
            </div>
        </div>
    )
}

export default FarewellBlock
