import React from "react"
import marketingimage from "../BlogPostMarketing/assets/marketing.webp"
import bigdataimage from "../BlogPostBigData/assets/bigdata.webp"
import dashboardsimage from "../BlogPostDashboards/assets/dashboards.webp"
import masterimage from "../BlogPostMaster/assets/master.webp"
import mobileimage from "../BlogPostMobile/assets/mobile.webp"
import { BlogCard } from "@/entities/BlogCard/BlogCard"

import cls from "./companyblog.module.scss"
import { useNavigate } from "react-router-dom"

export const CompanyBlog = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className={cls.content}>
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/marketing")
                    }
                    image={marketingimage}
                    title={
                        "Boost Marketing with Data Holder's Data Management"
                    }
                    description={
                        "Product metrics storage plays a crucial role in a successful business strategy. They enable companies to track and analyze.."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/bigdata")
                    }
                    image={bigdataimage}
                    title={
                        "Revolutionizing Big Data: Data Holder's Advanced Visualizati..."
                    }
                    description={
                        "With advancements in technology and the exponential growth of data volumes, companies have increasingly faced the.."
                    }
                    date={"October 26, 2023"}
                />

                <BlogCard
                    onClick={() =>
                        navigate(
                            "/companyblog/dashboardspost"
                        )
                    }
                    image={dashboardsimage}
                    title={
                        "Boost Productivity with Data Holder Dashboards"
                    }
                    description={
                        "Productivity is a key factor in modern business success, and one of the important tools that contributes to its enhancement.."
                    }
                    date={"October 26, 2023"}
                />

                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/masterpost")
                    }
                    title={
                        "Master IT Data Management with Data Holder"
                    }
                    image={masterimage}
                    description={
                        "How you can set personal boundaries to reduce stress and..."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/mobilepost")
                    }
                    title={
                        "6 new interesting mobile apps to grow your audience on"
                    }
                    image={mobileimage}
                    description={
                        "Free Forever Workspaces can have an unlimited number of members and guests at no.."
                    }
                    date={"October 26, 2023"}
                />
            </div>
        </>
    )
}
