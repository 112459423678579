import React from "react"
import { ReactComponent as ArrowIcon } from "@/shared/assets/arrowRightIcon.svg"
import marketingimg from "./assets/marketing.webp"
import cls from "./marketing.module.scss"
import { BlogCard } from "@/entities/BlogCard/BlogCard"
import mobileimage from "../BlogPostMobile/assets/mobile.webp"
import bigdataimage from "../BlogPostBigData/assets/bigdata.webp"
import { useNavigate } from "react-router-dom"
export const BlogPostMarketing = () => {
    const navigate = useNavigate()
    return (
        <div className={cls.content}>
            <div className={cls.title}>
                <h2>
                    Boost Marketing with Data Holder's Data
                    Management
                </h2>
                <div className={cls.date}>
                    <span>Posted on</span>
                    <span className={cls.num}>
                        October 26, 2023
                    </span>
                </div>
            </div>
            <img
                className={cls.image}
                src={marketingimg}
            />
            <p>
                Product metrics storage plays a crucial role
                in a successful business strategy. They
                enable companies to track and analyze
                product performance, identify strengths and
                weaknesses, and make informed decisions for
                further development. In today's world, where
                data becomes the currency of success, having
                convenient tools for storing and visualizing
                product metrics is essential.
            </p>
            <p>
                Undoubtedly, proper product metrics storage
                contributes to enhancing business efficiency
                and reducing risks. By analyzing key
                indicators and trends, companies can
                promptly respond to market changes, identify
                new opportunities to improve products, and
                ensure customer satisfaction.
            </p>
            <p>
                For effective storage and visualization of
                product metrics, companies can utilize the
                innovative tool Data Holder. The Data Holder
                website offers convenient features for
                creating dashboards, graphs, and diagrams
                that help companies track key metrics and
                analyze them in real-time. With Data Holder,
                the process of storing and visualizing
                product metrics becomes convenient and
                efficient, enabling companies to make
                informed decisions based on data and improve
                their operations.
            </p>
            <div className={cls.pagination}>
                <div
                    onClick={() =>
                        navigate("/companyblog/mobilepost")
                    }
                    className={cls.prevPost}>
                    <ArrowIcon className={cls.prevArrow} />
                    <p>Previous post</p>
                </div>
                <div
                    onClick={() =>
                        navigate("/companyblog/bigdata")
                    }
                    className={cls.nextPost}>
                    <p>Next post</p>
                    <ArrowIcon className={cls.nextArrow} />
                </div>
            </div>

            <div className={cls.posts}>
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/mobilepost")
                    }
                    title={
                        "6 new interesting mobile apps to grow your audience on"
                    }
                    image={mobileimage}
                    description={
                        "Free Forever Workspaces can have an unlimited number of members and guests at no.."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/bigdata")
                    }
                    image={bigdataimage}
                    title={
                        "Revolutionizing Big Data: Data Holder's Advanced Visualizati..."
                    }
                    description={
                        "With advancements in technology and the exponential growth of data volumes, companies have increasingly faced the.."
                    }
                    date={"October 26, 2023"}
                />
            </div>
        </div>
    )
}
