import React, { useEffect, useState } from "react"
import Button from "@/shared/ui/Button/Button"
import axios from "axios"
import { PaymentStatus } from "./PaymentStatus/PaymentStatus"
import { TextField } from "@/shared/ui/TextField/TextField"
import { Checkbox } from "@/shared/ui/Checkbox/Checkbox"
import { useCookies } from "react-cookie"
import { Link } from "react-router-dom"
import cls from "./payment.module.scss"

const PaymentDetail = ({ link, setOpen }) => {
    const [checked, setChecked] = useState(false)

    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [emailValid, setEmailValid] = useState(true)

    const [status, setStatus] = useState("Start")
    const [paymentID, setPaymentID] = useState(0)

    const onSubmit = async () => {
        setStatus("Waiting")
        const secretLink = await axios.post(
            `${window.location.origin}/api/v1/url/${link}`,
            {
                email: email,
                first_name: name,
                last_name: lastName,
            }
        )
        let count = 0
        async function subscribe() {
            let response = await axios
                .post(
                    `${window.location.origin}/api/v1/payment`,
                    {
                        first_name: name,
                        last_name: lastName,
                        email: email,
                    }
                )
                .catch((err) => {
                    if (count === 29) {
                        setStatus("Failed")
                        setPaymentID(0)
                        return
                    }
                    if (err.response.status == 502) {
                        count++
                        subscribe()
                    } else if (
                        err.response.status !== 200
                    ) {
                        count++
                        new Promise((resolve) =>
                            setTimeout(resolve, 1000)
                        )
                        subscribe()
                    }
                })
                .then((response) => {
                    if (response) {
                        let message = response
                        if (message.data.success === true) {
                            setStatus("Success")
                            setPaymentID(
                                message.data.payment_id
                            )
                        } else {
                            setStatus("Failed")
                        }
                        subscribe()
                    }
                })
        }
        subscribe()
    }

    const isValidEmail = (value) => {
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regEmail.test(value)) {
            setEmailValid(false)
            setCookie("email_valid", false)
        } else {
            setEmailValid(true)
            setCookie("email_valid", true)
        }
        setCookie("email", value)
        setEmail(value)
    }

    const nameHandler = (value) => {
        setName(value)
        setCookie("name", value)
    }

    const lastNameHandler = (value) => {
        setLastName(value)
        setCookie("last_name", value)
    }

    const [cookie, setCookie, removeCookie] = useCookies()

    useEffect(() => {
        if (cookie.accept_cookies) {
            setName(cookie.name ?? "")
            setLastName(cookie.last_name ?? "")
            setEmail(cookie.email ?? "")
            setEmailValid(
                cookie.email?.trim() === "" ||
                    cookie?.email === undefined
                    ? true
                    : cookie.email_valid
            )
        }
    }, [emailValid])
    const render = (status) => {
        if (status === "Start") {
            return (
                <div className={cls.PaymentDetail}>
                    <h2>Payment Details</h2>
                    <div className={cls.personalInfo}>
                        <TextField
                            className={cls.textField}
                            label='First name*'
                            placeholder={"Your name"}
                            value={name}
                            setValue={nameHandler}
                        />
                        <TextField
                            className={cls.textField}
                            label='Last name*'
                            placeholder={"Your last name"}
                            value={lastName}
                            setValue={lastNameHandler}
                        />
                        <TextField
                            className={cls.textField}
                            label='Email*'
                            type={"email"}
                            placeholder={"Your email"}
                            value={email}
                            setValue={isValidEmail}
                            valid={emailValid}
                            description={
                                emailValid
                                    ? "The message will be sent on this mail"
                                    : "invalid email format"
                            }
                        />
                    </div>

                    <Checkbox
                        className={cls.privacyLink}
                        id={"todo"}
                        value={checked}
                        setValue={setChecked}>
                        <p>
                            I accept
                            <Link
                                className={
                                    cls.privacyLegacyLink
                                }
                                to='/policy_legacy'>
                                <span
                                    className={
                                        cls.privacyLink
                                    }>
                                    {" "}
                                    policy legacy
                                </span>
                            </Link>
                        </p>
                    </Checkbox>

                    <Button
                        variant={
                            Boolean(
                                String(name)?.trim() &&
                                    String(
                                        lastName
                                    )?.trim() &&
                                    String(email)?.trim() &&
                                    checked &&
                                    emailValid
                            )
                                ? "primary"
                                : "disabled"
                        }
                        onClick={() => onSubmit()}
                        className={cls.paymentButton}
                        link={false}>
                        Apply
                    </Button>
                </div>
            )
        } else if (status === "Success") {
            return (
                <PaymentStatus
                    payInfo={{
                        email: email,
                        PaymentID: paymentID,
                    }}
                    onClick={() => {
                        setOpen(false)
                        window.scrollTo(0, 0)
                    }}
                    status='Success'
                />
            )
        } else if (status === "Failed") {
            return (
                <PaymentStatus
                    payInfo={{ email: email }}
                    status='Failed'
                    onClick={() => setStatus("Start")}
                />
            )
        } else if (status === "Waiting") {
            return (
                <PaymentStatus
                    payInfo={{ email: email }}
                    status='Waiting'
                    onClick={() => {
                        setOpen(false)
                        window.scrollTo(0, 0)
                    }}
                />
            )
        }
    }

    return render(status)
}

export default PaymentDetail
