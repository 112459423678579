import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"

import cls from "./Frame.module.scss"

export const Frame = ({ className, children }) => {
    return (
        <div className={classNames(cls.Frame, [className])}>
            <div className={cls.texture} />
            <div className={cls.topBlock}>
                <div className={cls.Dots}>
                    <svg
                        width='58'
                        height='14'
                        viewBox='0 0 58 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <circle
                            cx='7'
                            cy='7'
                            r='7'
                            fill='white'
                        />
                        <circle
                            cx='29'
                            cy='7'
                            r='7'
                            fill='white'
                        />
                        <circle
                            cx='51.0001'
                            cy='7'
                            r='7'
                            fill='white'
                        />
                    </svg>
                </div>
                <div className={cls.addressBar}>
                    <p>www.dataholder.io</p>
                </div>
            </div>
            <div className={cls.image}>{children}</div>
        </div>
    )
}
