import React from "react"
import { ReactComponent as ArrowIcon } from "@/shared/assets/arrowRightIcon.svg"
import dashboards from "./assets/master.webp"
import { ReactComponent as VMark } from "@/shared/assets/vMark.svg"
import { BlogCard } from "@/entities/BlogCard/BlogCard"
import dashboardsimage from "../BlogPostDashboards/assets/dashboards.webp"
import mobileimage from "../BlogPostMobile/assets/mobile.webp"
import cls from "./master.module.scss"
import Button from "@/shared/ui/Button/Button"
import { useNavigate } from "react-router-dom"

export const BlogPostMaster = () => {
    const navigate = useNavigate()
    return (
        <div className={cls.content}>
            <div className={cls.title}>
                <h2>
                    Master IT Data Management with Data
                    Holder
                </h2>
                <div className={cls.date}>
                    <span>Posted on</span>
                    <span className={cls.num}>
                        October 26, 2023
                    </span>
                </div>
            </div>
            <div className={cls.withImage}>
                <img
                    className={cls.image}
                    src={dashboards}
                />
                <div className={cls.text}>
                    <p>
                        The Importance and Challenges of
                        Storing Large Volumes of Data in IT
                        ProjectsIn the realm of IT projects,
                        the handling of large volumes of
                        data, including metrics, logs, and
                        various datasets, plays a critical
                        role in success. These data
                        components are vital for monitoring
                        project progress, analyzing
                        performance, and ensuring
                        operational efficiency.
                    </p>
                    <p>
                        However, managing such vast amounts
                        of data poses significant challenges
                        that require careful consideration
                        and strategic implementation.
                    </p>
                </div>
            </div>
            <p>
                Importance of Data StorageEffective data
                storage is crucial for IT projects as it
                enables organizations to: Monitor
                Performance: Metrics and logs provide
                real-time insights into system performance,
                helping teams identify bottlenecks, optimize
                processes, and enhance overall efficiency.
            </p>
            <ul className={cls.list}>
                <li>
                    <h3>Facilitate Decision-Making:</h3>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>
                            Access to comprehensive datasets
                            allows for informed
                            decision-making based on
                            accurate and up-to-date
                            information.
                        </p>
                    </div>
                </li>
                <li>
                    <h3>Ensure Compliance and Security:</h3>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>
                            Proper data storage practices
                            ensure compliance with
                            regulations and industry
                            standards while safeguarding
                            sensitive information from
                            breaches and unauthorized
                            access.
                        </p>
                    </div>
                </li>
                <li>
                    <h3>Scalability:</h3>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>
                            IT projects often experience
                            rapid data growth, necessitating
                            scalable storage solutions
                            capable of accommodating
                            expanding datasets without
                            compromising performance.
                        </p>
                    </div>
                </li>
                <li>
                    <h3>Complexity:</h3>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>
                            Integrating diverse data sources
                            and formats requires robust
                            infrastructure and sophisticated
                            data management strategies to
                            maintain coherence and
                            accessibility.
                        </p>
                    </div>
                </li>
                <li>
                    <h3>Cost Efficiency:</h3>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>
                            Balancing storage costs with
                            performance requirements and
                            regulatory compliance can be a
                            delicate task, requiring
                            optimization strategies to
                            maximize ROI.
                        </p>
                    </div>
                </li>
            </ul>
            <p>
                Data Holder is your solution for seamless
                storage, organization, and visualization of
                project data, including metrics and logs.
                Empower your IT projects with our intuitive
                platform designed to enhance data
                accessibility and analytical capabilities,
                ensuring informed decision-making and
                operational efficiency.
            </p>
            <a href='/client/register'>
                <Button className={cls.getStarted}>
                    Get Started
                </Button>
            </a>
            <div className={cls.pagination}>
                <div
                    onClick={() =>
                        navigate(
                            "/companyblog/dashboardspost"
                        )
                    }
                    className={cls.prevPost}>
                    <ArrowIcon className={cls.prevArrow} />
                    <p>Previous post</p>
                </div>
                <div
                    onClick={() =>
                        navigate("/companyblog/mobilepost")
                    }
                    className={cls.nextPost}>
                    <p>Next post</p>
                    <ArrowIcon className={cls.nextArrow} />
                </div>
            </div>

            <div className={cls.posts}>
                <BlogCard
                    onClick={() =>
                        navigate(
                            "/companyblog/dashboardspost"
                        )
                    }
                    image={dashboardsimage}
                    title={
                        "Boost Productivity with Data Holder Dashboards"
                    }
                    description={
                        "Productivity is a key factor in modern business success, and one of the important tools that contributes to its enhancement.."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/mobilepost")
                    }
                    title={
                        "6 new interesting mobile apps to grow your audience on"
                    }
                    image={mobileimage}
                    description={
                        "Free Forever Workspaces can have an unlimited number of members and guests at no.."
                    }
                    date={"October 26, 2023"}
                />
            </div>
        </div>
    )
}
