import React, { useEffect, useState } from "react"
import cls from "./logo.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link, useLocation } from "react-router-dom"
import useResize from "@/shared/lib/hooks/useResize"

export const Logo = ({ className, color }) => {
    const location = useLocation()
    const windowWidth = useResize()

    const acnhorRender = (component) => {
        return (
            <AnchorLink
                style={{ textDecoration: "none" }}
                href='#Home'
                offset={() => 100}>
                {component}
            </AnchorLink>
        )
    }

    const linkRender = (component) => {
        return (
            <Link
                style={{ textDecoration: "none" }}
                to={"/"}>
                {component}
            </Link>
        )
    }
    const render = (component) => {
        if (location.pathname === "/") {
            return acnhorRender(component)
        } else {
            return linkRender(component)
        }
    }
    return render(
        <div className={classNames(cls.Logo, [className])}>
            <svg
                width='49'
                height='54'
                viewBox='0 0 49 54'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M44.7988 13.1937L26.4141 2.57936C25.2738 1.92097 23.8688 1.92097 22.7284 2.57936L4.34382 13.1937C3.20346 13.8521 2.50097 15.0689 2.50097 16.3856V37.6144C2.50097 38.9311 3.20346 40.1479 4.34382 40.8063L22.7284 51.4206C23.8688 52.079 25.2738 52.079 26.4141 51.4206L44.7988 40.8063C45.9391 40.1479 46.6416 38.9311 46.6416 37.6144V16.3856C46.6416 15.0689 45.9391 13.8521 44.7988 13.1937Z'
                    fill='#EEF9FF'
                    stroke='#EEF9FF'
                    strokeWidth='2.57143'
                />
                <mask
                    id='mask0_3179_3285'
                    // style={'mask-type:alpha'}
                    maskUnits='userSpaceOnUse'
                    x='3'
                    y='3'
                    width='43'
                    height='48'>
                    <path
                        d='M23.3713 3.69282C24.1139 3.2641 25.0287 3.2641 25.7713 3.69282L44.1559 14.3072C44.8985 14.7359 45.3559 15.5282 45.3559 16.3856V37.6144C45.3559 38.4718 44.8985 39.2641 44.1559 39.6928L25.7713 50.3072C25.0287 50.7359 24.1139 50.7359 23.3713 50.3072L4.98668 39.6928C4.24412 39.2641 3.78668 38.4718 3.78668 37.6144V16.3856C3.78668 15.5282 4.24412 14.7359 4.98668 14.3072L23.3713 3.69282Z'
                        fill='#D9D9D9'
                    />
                </mask>
                <g mask='url(#mask0_3179_3285)'>
                    <path
                        opacity='0.9'
                        d='M3.89453 31.7389C3.89453 30.2459 5.47108 29.2793 6.80167 29.9565L23.4786 38.4438C24.1492 38.7851 24.5715 39.4738 24.5715 40.2263V67.4615C24.5715 68.5661 23.676 69.4615 22.5715 69.4615H5.89453C4.78996 69.4615 3.89453 68.5661 3.89453 67.4615V31.7389Z'
                        fill='#BB6BD9'
                    />
                    <path
                        opacity='0.9'
                        d='M14.2329 24.3543C14.2329 22.8613 15.8095 21.8947 17.14 22.5718L33.817 31.0592C34.4875 31.4005 34.9098 32.0892 34.9098 32.8416V67.4615C34.9098 68.5661 34.0144 69.4615 32.9098 69.4615H16.2329C15.1283 69.4615 14.2329 68.5661 14.2329 67.4615V24.3543Z'
                        fill='#0944CC'
                    />
                    <path
                        opacity='0.9'
                        d='M24.5713 16.9696C24.5713 15.4767 26.1478 14.51 27.4784 15.1872L44.1553 23.6746C44.8259 24.0158 45.2482 24.7046 45.2482 25.457V67.4615C45.2482 68.5661 44.3528 69.4615 43.2482 69.4615H26.5713C25.4667 69.4615 24.5713 68.5661 24.5713 67.4615V16.9696Z'
                        fill='#0CF18A'
                    />
                </g>
            </svg>

            <span style={{ color: color }}>
                {windowWidth > 1025 ? "DataHolder" : ""}
            </span>
        </div>
    )
}
