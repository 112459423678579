import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import stayInformed from "@/shared/assets/images/stayInformed.svg"
import projects from "@/shared/assets/dataHolderFootage.webp"
import { ReactComponent as VMark } from "@/shared/assets/vMark.svg"
import { Frame } from "@/shared/ui/Frame/Frame"

import cls from "./textcontentblock.module.scss"
import { useTranslation } from "react-i18next"
import Button from "@/shared/ui/Button/Button"

export const TextContentBlock = ({ className }) => {
    const { t } = useTranslation()
    return (
        <div
            className={classNames(cls.content, [
                className,
            ])}>
            <h2>{t("aboutPage.textBlockTitle")}</h2>
            <Frame className={cls.frame}>
                <img alt="projects" src={projects} />
            </Frame>
            <div className={cls.textContainer}>
                <p>
                    {t("aboutPage.textBlockDescription1")}
                </p>
                <p>
                    {t("aboutPage.textBlockDescription2")}
                </p>
            </div>
            <div className={cls.advantages}>
                <div className={cls.advantagesText}>
                    <h3>{t("aboutPage.listTitle")}</h3>
                    <ul className={cls.list}>
                        <li>
                            <VMark className={cls.vMark} />
                            <p>
                                <b>
                                    {t(
                                        "aboutPage.listTitle1"
                                    )}
                                </b>{" "}
                                {t(
                                    "aboutPage.listDescription1"
                                )}
                            </p>
                        </li>
                        <li>
                            <VMark className={cls.vMark} />
                            <p>
                                <b>
                                    {t(
                                        "aboutPage.listTitle2"
                                    )}
                                </b>{" "}
                                {t(
                                    "aboutPage.listDescription2"
                                )}
                            </p>
                        </li>
                        <li>
                            <VMark className={cls.vMark} />
                            <p>
                                <b>
                                    {t(
                                        "aboutPage.listTitle3"
                                    )}
                                </b>{" "}
                                {t(
                                    "aboutPage.listDescription3"
                                )}
                            </p>
                        </li>
                    </ul>
                </div>
                <div className={cls.graphic}>
                    <img
                        src={stayInformed}
                        alt='Graphical representation'
                        className={cls.graph}
                    />
                </div>
            </div>
            <p style={{ marginTop: "32px" }}>
                {t("aboutPage.listSubDiscription")}
            </p>
            <Button
                onClick={() => {
                    window.open(
                        window.location.origin +
                            "/client/register"
                    )
                }}
                className={cls.getStartedButton}>
                {t("homeBlock.button")}
            </Button>
        </div>
    )
}
