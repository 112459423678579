import React, { useEffect, useState } from "react"
import firstPerson from "./avatars/firstPerson.webp"
import secondPerson from "./avatars/secondPerson.webp"
import thirdPerson from "./avatars/thirdPerson.webp"
import fourthPerson from "./avatars/fourthPerson.webp"
import { ReactComponent as CircleArrow } from "@/shared/assets/circleArrowRight.svg"

import cls from "./blogBlock.module.scss"
import Post from "@/entities/Post/Post"
import { classNames } from "@/shared/lib/utils/classNames"
import useResize from "@/shared/lib/hooks/useResize"
import { useTranslation } from "react-i18next"

const BlogBlock = ({ id }) => {
    const { t } = useTranslation()
    const posts = [
        {
            id: "post1",
            text: t("blogBlock.posts.firstPost.text"),
            name: t("blogBlock.posts.firstPost.name"),
            role: t("blogBlock.posts.firstPost.role"),
            Avatar: firstPerson,
        },
        {
            id: "post2",
            text: t("blogBlock.posts.secondPost.text"),
            name: t("blogBlock.posts.secondPost.name"),
            role: t("blogBlock.posts.secondPost.role"),
            Avatar: secondPerson,
        },
        {
            id: "post3",
            text: t("blogBlock.posts.thirdPost.text"),
            name: t("blogBlock.posts.thirdPost.name"),
            role: t("blogBlock.posts.thirdPost.role"),
            Avatar: thirdPerson,
        },
        {
            id: "post4",
            text: t("blogBlock.posts.fourthPost.text"),
            name: t("blogBlock.posts.fourthPost.name"),
            role: t("blogBlock.posts.fourthPost.role"),
            Avatar: fourthPerson,
        },
    ]

    const windowWidth = useResize()

    const scrollPostList = (distance) => {
        const element = document.getElementById("postList")
        element.scrollTo({
            left: distance,
            top: 0,
            behavior: "smooth",
        })
    }
    const [currentIndex, setIndex] = useState(0)

    useEffect(() => {
        if (windowWidth > 768) {
            scrollPostList(currentIndex * 504)
        } else {
            scrollPostList(currentIndex * 400)
        }
    }, [currentIndex])

    const handleLeftArrowClick = () => {
        setIndex((prev) => {
            if (prev === 0) {
                return 3
            }
            return prev - 1
        })
    }
    const handleRightArrowClick = () => {
        setIndex((prev) => {
            if (prev === 3) {
                return 0
            }
            return prev + 1
        })
    }

    return (
        <div className={cls.BlogBlock}>
            <div className={cls.textContainer}>
                <h2
                    id={id}
                    className={cls.title}>
                    {t("blogBlock.title")}
                </h2>
                <p className={cls.text}>
                    {t("blogBlock.description")}
                </p>
            </div>
            <div className={cls.postsContainer}>
                <div
                    id='postList'
                    className={cls.postsList}>
                    {posts.map((post) => (
                        <div key={post.id}>
                            <Post
                                id={post.id}
                                text={post.text}
                                name={post.name}
                                role={post.role}
                                Avatar={post.Avatar}
                            />
                        </div>
                    ))}
                </div>
                <div className={cls.controller}>
                    <CircleArrow
                        onClick={() =>
                            handleLeftArrowClick()
                        }
                        className={classNames(
                            cls.arrow,
                            [cls.left],
                            {
                                [cls.disabled]:
                                    currentIndex === 0,
                            }
                        )}
                    />
                    <CircleArrow
                        onClick={() =>
                            handleRightArrowClick()
                        }
                        className={classNames(
                            cls.arrow,
                            [cls.right],
                            {
                                [cls.disabled]:
                                    currentIndex === 3,
                            }
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default BlogBlock
