import React, { useState, useEffect } from "react"

const useResize = () => {
    const [windowWidth, setWindowWidth] = useState(
        window.innerWidth
    )
    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth)
        })
        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth)
            })
        }
    }, [windowWidth])

    return windowWidth
}

export default useResize
