import React, { useEffect, useState } from "react"

import cls from "./cookie.module.scss"
import Button from "@/shared/ui/Button/Button"
import { classNames } from "@/shared/lib/utils/classNames"
import { useCookies } from "react-cookie"
import { Link } from "react-router-dom"

const CookieNotice = () => {
    const [hide, setHide] = useState(false)
    const [cookie, setCookie] = useCookies()
    const [visited, setVisited] = useState(true)

    useEffect(() => {
        if (typeof cookie?.accept_cookies === "undefined") {
            setVisited(false)
        }
    }, [cookie])

    const onDecline = () => {
        setHide(true)
        setCookie("accept_cookies", false, {
            maxAge: 2592000,
        })
    }

    const onAccept = () => {
        setHide(true)
        setCookie("accept_cookies", true, {
            maxAge: 2592000,
        })
    }
    return (
        <div
            className={classNames(cls.CookieNotice, [], {
                [cls.hide]: hide,
                [cls.visited]: visited,
            })}>
            <p className={cls.text}>
                In simple terms, cookie text is the actual
                text a user will see on the dialogue box
                when they are presented with the choice to
                either accept cookies or reject them.{" "}
                <Link
                    style={{
                        color: "white",
                        textDecoration: "underline",
                    }}
                    to='/policy_legacy'>
                    More about cookie
                </Link>
            </p>
            <div className={cls.buttonContainer}>
                <Button
                    link={false}
                    size='extraSmall'
                    className={cls.decline}
                    onClick={() => onDecline()}>
                    Decline
                </Button>
                <Button
                    link={false}
                    size='extraSmall'
                    className={cls.accept}
                    onClick={() => onAccept()}
                    variant='white'>
                    {" "}
                    Accept all cookies
                </Button>
            </div>
        </div>
    )
}

export default CookieNotice
