import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import { ReactComponent as LongArrow } from "@/shared/assets/longArrowRight.svg"

import cls from "./pagination.module.scss"

const Pagination = ({
    className,
    currentPage,
    totalPages,
    onClickLeft,
    onClickRight,
}) => {
    return (
        <div
            className={classNames(cls.switcher, [
                className,
            ])}>
            <LongArrow
                onClick={onClickLeft}
                className={classNames(cls.longArrow, [
                    cls.left,
                ])}
            />
            <div className={cls.Pagination}>
                <span className={cls.currentPage}>
                    {currentPage}
                </span>
                <span className={cls.totalPages}>
                    {" "}
                    / {totalPages}
                </span>
            </div>
            <LongArrow
                onClick={onClickRight}
                disabled={currentPage === totalPages}
                className={classNames(cls.longArrow, [
                    cls.right,
                ])}
            />
        </div>
    )
}

export default Pagination
