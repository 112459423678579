import React from "react"
import { AboutSection } from "./About/AboutSection"
import { FeaturesSection } from "./Features/FeaturesSection"

import cls from "./about.module.scss"

const AboutBlock = ({ id }) => {
    return (
        <div
            className={cls.AboutBlock}>
            <AboutSection className={cls.AboutProjects} />
            <FeaturesSection
                id={id}
                className={cls.features}
            />
        </div>
    )
}

export default AboutBlock
