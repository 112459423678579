import React, { useEffect } from "react"
import Header from "@/widgets/Header/Header"
import Footer from "@/widgets/Footer/Footer"
import background from "@/shared/assets/images/BG.webp"
import { TextContentBlock } from "./TextContentBlock/TextContentBlock"
import { IdealFor } from "./IdealFor/IdealFor"
import { OurTeam } from "./OurTeam/OurTeam"
import bgBottom from "./assets/bgBottom.png"
import projects from "@/shared/assets/dataHolderFootage.webp"
import { ReactComponent as BackGraph } from "@/shared/assets/backGraph.svg"
import Button from "@/shared/ui/Button/Button"
import { Frame } from "@/shared/ui/Frame/Frame"
import cls from "./aboutpage.module.scss"
import { useTranslation } from "react-i18next"

export const AboutPage = () => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Header />
            <div className={cls.container}>
                <div className={cls.AboutPage}>
                    <div className={cls.title}>
                        <h1>{t("aboutPage.mainTitle")}</h1>
                    </div>
                    <TextContentBlock />
                    <IdealFor />
                    <OurTeam />
                </div>
                <div
                    style={{
                        backgroundImage: `url(${bgBottom})`,
                    }}
                    className={cls.ending}>
                    <div className={cls.getTrial}>
                        <div className={cls.getTrialText}>
                            <h2>
                                {t("aboutPage.getTrial")}
                            </h2>
                            <p>{t("aboutPage.discount")}</p>
                        </div>
                        <Button
                            className={cls.getButton}
                            size='large'>
                            {t("aboutPage.getDH")}
                        </Button>
                    </div>
                    <Frame className={cls.frame}>
                        <img src={projects} />
                    </Frame>
                    <BackGraph className={cls.BackGraph} />
                </div>
                <img
                    src={background}
                    className={cls.bgWave}
                />
            </div>
            <Footer className={cls.footer} />
        </>
    )
}
