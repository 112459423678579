import React, { useState } from "react"
import Header from "@/widgets/Header/Header"
import background from "@/shared/assets/images/BG.webp"
import Footer from "@/widgets/Footer/Footer"

import cls from "./companylayout.module.scss"
import { Outlet } from "react-router-dom"

export const CompanyBlogLayout = () => {
    return (
        <>
            <div className={cls.container}>
                <div className={cls.CompanyBlog}>
                    <Header />
                    <div className={cls.title}>
                        <h1>Our Company's Blog</h1>
                    </div>
                    <div className={cls.content}>
                        <Outlet />
                    </div>
                </div>

                <img
                    className={cls.bgWave}
                    src={background}
                    alt='bg'
                />
            </div>
            <Footer className={cls.footer} />
        </>
    )
}
