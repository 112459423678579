import React, { useState } from "react"

import PricingCard from "@/entities/PricingCard/PricingCard"
import Button from "@/shared/ui/Button/Button"

import { ReactComponent as Illustration } from "./assets/illustration.svg"
import { ReactComponent as PersonalOffer } from "./assets/personalOfffer.svg"
import Modal from "@/shared/ui/Modal/Modal"
import ContactForm from "./ContactForm/ContactForm"

import cls from "./pricing.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import useResize from "@/shared/lib/hooks/useResize"
import { useTranslation } from "react-i18next"

const PricingBlock = ({ id }) => {
    const { t } = useTranslation()
    const priceList = [
        {
            plan: t("plans.basic.plan"),
            link: "Basic",
            description: t("plans.basic.description"),
            price: 5,
            prefers: [
                {
                    title: t(
                        "plans.prefers.personalAccess"
                    ),
                    available: true,
                },
                {
                    title: t("plans.basic.projects"),
                    available: true,
                },
                {
                    title: t("plans.prefers.communication"),
                    available: false,
                },
                {
                    title: t("plans.prefers.variety"),
                    available: false,
                },
                {
                    title: t("plans.prefers.storage"),
                    available: false,
                },
                {
                    title: t("plans.prefers.alarms"),
                    available: false,
                },
                {
                    title: t("plans.prefers.logs"),
                    available: false,
                },
            ],
        },
        {
            plan: t("plans.basicPro.plan"),
            link: "Basic Pro",
            description: t("plans.basicPro.description"),
            price: 25,
            prefers: [
                {
                    title: t(
                        "plans.prefers.personalAccess"
                    ),
                    available: true,
                },
                {
                    title: t("plans.basicPro.projects"),
                    available: true,
                },
                {
                    title: t("plans.prefers.communication"),
                    available: true,
                },
                {
                    title: t("plans.prefers.variety"),
                    available: false,
                },
                {
                    title: t("plans.prefers.storage"),
                    available: false,
                },
                {
                    title: t("plans.prefers.alarms"),
                    available: false,
                },
                {
                    title: t("plans.prefers.logs"),
                    available: false,
                },
            ],
        },
        {
            plan: t("plans.advanced.plan"),
            link: "Advanced",
            description: t("plans.advanced.description"),
            price: 125,
            recomended: true,
            prefers: [
                {
                    title: t(
                        "plans.prefers.personalAccess"
                    ),
                    available: true,
                },
                {
                    title: t("plans.advanced.projects"),
                    available: true,
                },
                {
                    title: t("plans.prefers.communication"),
                    available: true,
                },
                {
                    title: t("plans.prefers.variety"),
                    available: true,
                },
                {
                    title: t("plans.prefers.storage"),
                    available: false,
                },
                {
                    title: t("plans.prefers.alarms"),
                    available: false,
                },
                {
                    title: t("plans.prefers.logs"),
                    available: false,
                },
            ],
        },
        {
            plan: t("plans.advancedPro.plan"),
            link: "Advanced Pro",
            description: t("plans.advancedPro.description"),
            price: 250,
            prefers: [
                {
                    title: t(
                        "plans.prefers.personalAccess"
                    ),
                    available: true,
                },
                {
                    title: t("plans.advanced.projects"),
                    available: true,
                },
                {
                    title: t("plans.prefers.communication"),
                    available: true,
                },
                {
                    title: t("plans.prefers.variety"),
                    available: true,
                },
                {
                    title: t("plans.prefers.storage"),
                    available: true,
                },
                {
                    title: t("plans.prefers.alarms"),
                    available: true,
                },
                {
                    title: t("plans.prefers.logs"),
                    available: false,
                },
            ],
        },
        {
            plan: t("plans.dataPro.plan"),
            link: "Data Pro",
            description: t("plans.dataPro.description"),
            price: 700,
            prefers: [
                {
                    title: t(
                        "plans.prefers.personalAccess"
                    ),
                    available: true,
                },
                {
                    title: t("plans.advanced.projects"),
                    available: true,
                },
                {
                    title: t("plans.prefers.communication"),
                    available: true,
                },
                {
                    title: t("plans.prefers.variety"),
                    available: true,
                },
                {
                    title: t("plans.prefers.storage"),
                    available: true,
                },
                {
                    title: t("plans.prefers.alarms"),
                    available: true,
                },
                {
                    title: t("plans.prefers.logs"),
                    available: true,
                },
            ],
        },
    ]

    const windowWidth = useResize()

    const [isOpen, setOpen] = useState()
    return (
        <div
            id='Pricing_Anchor'
            className={cls.PricingBlock}>
            <Illustration className={cls.Illustration} />
            <div className={cls.title}>
                <h2>{t("pricingBlock.title")}</h2>
                <p>
                    {windowWidth > 768
                        ? t("pricingBlock.description1")
                        : t(
                              "pricingBlock.descriptionMobile"
                          )}
                </p>
            </div>
            <div
                id={id}
                className={cls.cardsList}>
                {priceList.map((item) => (
                    <PricingCard
                        recomended={item.recomended}
                        description={item.description}
                        plan={item.plan}
                        price={item.price}
                        prefers={item.prefers}
                        key={item.plan}
                        link={item.link}
                    />
                ))}
                <div className={cls.personalOffer}>
                    <div
                        className={classNames(
                            cls.PricingCard,
                            [],
                            {
                                [cls.mobile]:
                                    windowWidth < 769,
                            }
                        )}>
                        <PersonalOffer />
                        <div
                            className={
                                cls.titleContainerOffer
                            }>
                            <h2>
                                {t(
                                    "plans.personalOffer.title"
                                )}
                            </h2>
                            <p>
                                {t(
                                    "plans.personalOffer.content"
                                )}
                            </p>
                        </div>
                        <Button
                            className={cls.orderButton}
                            onClick={() => setOpen(true)}
                            link={false}
                            size='medium'
                            variant='outlined'>
                            {t(
                                "plans.personalOffer.bookACall"
                            )}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={cls.freeTrial}>
                <div className={cls.titleContainer}>
                    <h3>{t("plans.freePlan.title")}</h3>
                    <div className={cls.price}>
                        <sup className={cls.dollar}>$</sup>0
                        <span className={cls.period}>
                            /Mo
                        </span>
                    </div>
                </div>
                <a href='/client/register'>
                    <Button
                        size='medium'
                        link={false}
                        variant='secondary'>
                        {t("plans.freePlan.buttonText")}
                    </Button>
                </a>
            </div>

            <Modal
                isOpen={isOpen}
                setOpen={setOpen}>
                <ContactForm />
            </Modal>
        </div>
    )
}

export default PricingBlock
