import React, { useEffect, useState } from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import AnchorLink from "react-anchor-link-smooth-scroll"
import cls from "./navigation.module.scss"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

const Navigation = ({
    className,
    onClick,
    color,
    mobile = false,
}) => {
    const location = useLocation()
    const { t } = useTranslation()

    const navItems = [
        {
            name: "Home",
            anchor: "Home",
        },
        {
            name: "About",
            anchor: "About_Anchor",
        },
        {
            name: "Pricing",
            anchor: "Pricing_Anchor",
        },
        {
            name: "UseCases",
            anchor: "Blog",
        },
        {
            name: "Blog",
            anchor: "Blog",
        },
        {
            name: "Documentation",
            anchor: "Blog",
        },
    ]

    const [current, setCurrent] = useState()

    useEffect(() => {
        const target = navItems.map((navItem) =>
            navItem.name === "Documentation" || navItem.name === "Blog"
                ? document.getElementById("UseCases")
                : document.getElementById(navItem.name)
        )
        const options = {
            rootMargin: mobile ? "-0px" : "-400px",
            threshold: mobile ? 0.2 : 0,
        }
        const navigationCallback = function (
            entries,
            observer
        ) {
            entries.forEach((entry) => {
                let prevIntersectionRation = 0
                if (
                    entry.isIntersecting &&
                    entry.intersectionRatio >
                        prevIntersectionRation
                ) {
                    prevIntersectionRation =
                        entry.intersectionRatio
                    if (
                        window.scrollY <
                        (mobile ? 200 : 600)
                    ) {
                        setCurrent("Home")
                    } else {
                        setCurrent(entry.target.id)
                    }
                }
            })
        }
        const observer = new IntersectionObserver(
            navigationCallback,
            options
        )
        target.forEach((item) => {
            if (location.pathname === "/") {
                observer.observe(item)
            }
        })
    }, [current, setCurrent])

    const renderNavigation = (item) => {
        if (location.pathname === "/") {
            return (
                <AnchorLink
                    className={cls.link}
                    href={"#" + item.anchor}
                    offset={() => 140}
                    key={item.name}>
                    <li
                        onClick={onClick}
                        className={classNames(
                            cls.item,
                            [],
                            {
                                [cls.active]:
                                    current === item.name,
                            }
                        )}>
                        <p>
                            {t(`navigation.${item.name.trim()}`)}
                        </p>
                    </li>
                </AnchorLink>
            )
        } else {
            return (
                <Link
                    style={{
                        textDecoration: "none",
                        color: "white",
                    }}
                    to='/'>
                    <li
                        className={classNames(
                            cls.item,
                            [],
                            {
                                [cls.active]:
                                    current === item.name,
                            }
                        )}>
                        <p>{item.name}</p>
                    </li>
                </Link>
            )
        }
    }
    const clickHandler = () => {
        window.open(
            window.location.origin +
                "/client/docs/help-center"
        )
    }
    return (
        <nav
            className={classNames(cls.Navigation, [
                className,
            ])}>
            <ul className={cls.list}>
                {navItems.map((item) => {
                    if (item.name === "Documentation") {
                        return (
                            <li
                                onClick={clickHandler}
                                className={classNames(
                                    cls.item,
                                    [cls[color]]
                                )}>
                                <p>
                                    {t(
                                        `navigation.${item.name.trim()}`
                                    )}
                                </p>
                            </li>
                        )
                    } else if (item.name === "Blog") {
                        return (
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                }}
                                to='/companyblog'>
                                <li
                                    className={classNames(
                                        cls.item,
                                        [],
                                        {
                                            [cls.active]:
                                                current ===
                                                item.name,
                                        }
                                    )}>
                                    <p>{item.name}</p>
                                </li>
                            </Link>
                        )
                    }
                    return renderNavigation(item)
                })}
            </ul>
        </nav>
    )
}

export default Navigation
