import React from "react"

import marketing from "./images/Developer.webp"
import delevoper from "./images/Bussineman.webp"
import bussineman from "./images/Marketing.webp"

import cls from "./idealfor.module.scss"
import { useTranslation } from "react-i18next"

export const IdealFor = ({ className }) => {
    const { t } = useTranslation()
    return (
        <div className={cls.IdealFor}>
            <h2>{t("aboutPage.dataHolderIdeal")}</h2>
            <ul className={cls.list}>
                <li>
                    <img
                        loading='lazy'
                        src={delevoper}
                        alt='Developer'
                    />
                    <div className={cls.textContainer}>
                        <h2>
                            {t("aboutPage.dhIdealTitle1")}
                        </h2>
                        <p>
                            {t(
                                "aboutPage.dhIdealDiscription1"
                            )}
                        </p>
                    </div>
                </li>
                <li className={cls.second}>
                    <div className={cls.textContainer}>
                        <h2>
                            {t("aboutPage.dhIdealTitle2")}
                        </h2>
                        <p>
                            {t(
                                "aboutPage.dhIdealDiscription2"
                            )}
                        </p>
                    </div>
                    <img
                        loading='lazy'
                        src={bussineman}
                        alt='bussineman'
                    />
                </li>
                <li>
                    <img
                        loading='lazy'
                        src={marketing}
                        alt='marketing'
                    />
                    <div className={cls.textContainer}>
                        <h2>
                            {t("aboutPage.dhIdealTitle3")}
                        </h2>
                        <p>
                            {t(
                                "aboutPage.dhIdealDiscription3"
                            )}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    )
}
