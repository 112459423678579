import React from "react"

import cls from "./post.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"

const Post = ({
    className,
    text,
    Avatar,
    name,
    role,
    id,
}) => {
    return (
        <div
            id={id}
            className={classNames(cls.Post, [className])}>
            <p className={cls.postText}>
                {window.innerWidth > 767
                    ? text.slice(0, 185)
                    : text.slice(0, 132)}
                ...
            </p>
            <div className={cls.profile}>
                <img
                    className={cls.avatar}
                    src={Avatar}
                    alt='person'
                    loading='lazy'
                />
                <div className={cls.nameContainer}>
                    <p className={cls.name}>{name}</p>
                    <p className={cls.role}>{role}</p>
                </div>
            </div>
        </div>
    )
}

export default Post
