import React, { useEffect, useRef, useState } from "react"
import { ReactComponent as ArrowDown } from "@/shared/assets/downArrow.svg"
import { classNames } from "@/shared/lib/utils/classNames"
import useClickOutside from "@/shared/lib/hooks/useClickOutside"
import { CSSTransition } from "react-transition-group"

import cls from "./select.module.scss"
import { Checkbox } from "@/shared/ui/Checkbox/Checkbox"

export const Select = ({
    options,
    className,
    title,
    selected,
    setValue,
    multiOptions,
}) => {
    const [expanded, setExpanded] = useState(false)

    const selectRef = useRef()

    useEffect(() => {
        window.addEventListener("scroll", () =>
            setExpanded(false)
        )

        return () => {
            window.removeEventListener("scroll", () =>
                setExpanded(false)
            )
        }
    })

    const arrowHandler = () => {
        setExpanded(!expanded)
    }

    useClickOutside(selectRef, () => setExpanded(false))

    const renderOption = (item, index) => {
        if (multiOptions) {
            return (
                <div
                    className={classNames(
                        cls.selectOption,
                        [],
                        {
                            [cls.firstOption]: index === 0,
                            [cls.lastOption]:
                                index ===
                                options.length - 1,
                            [cls.multi]: multiOptions,
                        }
                    )}
                    onClick={() => {
                        if (selected.includes(item)) {
                            setValue(
                                options.filter(
                                    (option) =>
                                        option !== item
                                )
                            )
                        } else {
                            setValue([...selected, item])
                            options.includes(
                                selected[index]
                            )
                        }
                    }}
                    key={index}>
                    {item}
                    <Checkbox
                        id={item}
                        value={selected.includes(item)}
                        setValue={() => {}}
                    />
                </div>
            )
        } else {
            return (
                <div
                    className={classNames(
                        cls.selectOption,
                        [],
                        {
                            [cls.firstOption]: index === 0,
                            [cls.lastOption]:
                                index ===
                                options.length - 1,
                            [cls.selected]:
                                selected === item,
                        }
                    )}
                    onClick={() => setValue(item)}
                    key={index}>
                    {item}
                </div>
            )
        }
    }
    return (
        <div
            ref={selectRef}
            onClick={arrowHandler}
            className={classNames(cls.SelectContainer, [
                className,
            ])}>
            <div className={cls.selected}>
                <p>{title ? title : selected}</p>
            </div>
            <CSSTransition
                in={expanded}
                timeout={300}>
                <div
                    className={classNames(
                        cls.selectMenu,
                        [],
                        {
                            [cls.expanded]: expanded,
                        }
                    )}>
                    {options.map((item, index) => {
                        return renderOption(item, index)
                    })}
                </div>
            </CSSTransition>
            <ArrowDown
                className={classNames(cls.Icon, [], {
                    [cls.expanded]: expanded,
                })}
                type='select-arrow'
            />
        </div>
    )
}
