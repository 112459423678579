import React from "react"
import { ReactComponent as AboutImage } from "@/shared/assets/aboutImage.svg"
import { ReactComponent as ArrowRight } from "@/shared/assets/arrowRightIcon.svg"
import { ReactComponent as AboutMiniImage } from "@/shared/assets/aboutMiniImage.svg"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./aboutsection.module.scss"
import { Link } from "react-router-dom"
import Button from "@/shared/ui/Button/Button"
import { useTranslation } from "react-i18next"

export const AboutSection = ({ className }) => {
    const { t } = useTranslation()
    return (
        <div
            id="About_Anchor"
            className={classNames(cls.AboutSection, [
                className,
            ])}>
            <AboutImage className={cls.aboutImage} />
            <div className={cls.textContainer}>
                <p className={cls.title}>
                    {t("aboutBlock.about.title1")}
                </p>
                <h2>{t("aboutBlock.about.title2")}</h2>
                <p>{t("aboutBlock.about.description")}</p>
                <Link
                    style={{ textDecoration: "none" }}
                    className={cls.readMoreButton}
                    to={"/about"}>
                    <Button
                        size='medium'
                        variant='secondary'
                        className={cls.readMoreButton}>
                        {t("aboutBlock.about.readMore")}
                        <ArrowRight
                            className={cls.ArrowRight}
                        />
                    </Button>
                </Link>

                <AboutMiniImage className={cls.miniImage} />
            </div>
        </div>
    )
}
