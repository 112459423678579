import React from "react"
import Button from "@/shared/ui/Button/Button"
import { ReactComponent as Dots } from "@/shared/assets/dots.svg"
import { ReactComponent as BackGraph } from "@/shared/assets/backGraph.svg"
import mobileBackground from "./assets/mobileBackground.png"
import footage from "@/shared/assets/dataHolderFootage.webp"
import cls from "./HomeBlock.module.scss"
import { Frame } from "@/shared/ui/Frame/Frame"
import { useTranslation } from "react-i18next"

const HomeBlock = ({ id }) => {
    const { t } = useTranslation()
    return (
        <div>
            <div className={cls.background}>
                <div
                    id={id}
                    className={cls.HomeBlock}>
                    <img
                        className={cls.bgImage}
                        src={mobileBackground}
                        alt='footage'
                    />
                    <div className={cls.titleContainer}>
                        <h1>
                            {t("homeBlock.title.part1")}
                        </h1>
                        <h1>
                            {t("homeBlock.title.part2")}
                        </h1>
                        <h1>
                            {t("homeBlock.title.part3")}
                        </h1>
                        <h4 className={cls.description}>
                            {t("homeBlock.description")}
                        </h4>
                        <a href='/client/register'>
                            <Button
                                className={cls.getButton}
                                link={false}
                                size='large'>
                                {t("homeBlock.button")}
                            </Button>
                        </a>
                        <BackGraph
                            className={cls.BackGraph}
                        />
                        <Dots className={cls.Dots} />
                    </div>
                    <div className={cls.frameContainer}>
                        <Frame className={cls.Frame}>
                            <img
                                src={footage}
                                alt='footage'
                            />
                        </Frame>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBlock
