import React from "react"
import { ReactComponent as ArrowIcon } from "@/shared/assets/arrowRightIcon.svg"
import { BlogCard } from "@/entities/BlogCard/BlogCard"
import marketingimage from "../BlogPostMarketing/assets/marketing.webp"
import dashboardsimage from "../BlogPostDashboards/assets/dashboards.webp"
import bigdata from "./assets/bigdata.webp"
import cls from "./bigdata.module.scss"
import { useNavigate } from "react-router-dom"

export const BlogPostBigData = () => {
    const navigate = useNavigate()
    return (
        <div className={cls.content}>
            <div className={cls.title}>
                <h2>
                    Revolutionizing Big Data: Data Holder's
                    Advanced Visualization Tools
                </h2>
                <div className={cls.date}>
                    <span>Posted on</span>
                    <span className={cls.num}>
                        October 26, 2023
                    </span>
                </div>
            </div>
            <img
                className={cls.image}
                src={bigdata}
            />
            <p>
                Over the past few years, the Big Data market
                has undergone significant changes. With
                advancements in technology and the
                exponential growth of data volumes,
                companies have increasingly faced the need
                for effective processing, analysis, and
                visualization of large datasets to make
                critical business decisions. This has led to
                a rising demand for tools and platforms
                specialized in handling Big Data.
            </p>
            <p>
                One such effective tool is the Data Holder
                website, which provides unique capabilities
                for data visualization. Through a variety of
                graphs, diagrams, and dashboards, Data
                Holder helps analysts and data processing
                specialists swiftly and accurately analyze
                information, make forecasts, and identify
                important trends. This innovative tool
                remains relevant and in high demand in the
                Big Data market, enabling companies to make
                efficient and well-founded decisions based
                on data.
            </p>
            <p>
                Together with Data Holder, companies can
                harness the power of Big Data to enhance
                their business processes, optimize
                strategies, and increase competitiveness in
                the market. Thanks to the innovative
                solutions and capabilities of Data Holder,
                the era of Big Data becomes more accessible
                and transparent for everyone striving to
                leverage data as a key resource for
                development.
            </p>
            <div className={cls.pagination}>
                <div
                    onClick={() =>
                        navigate("/companyblog/marketing")
                    }
                    className={cls.prevPost}>
                    <ArrowIcon className={cls.prevArrow} />
                    <p>Previous post</p>
                </div>
                <div
                    onClick={() =>
                        navigate(
                            "/companyblog/dashboardspost"
                        )
                    }
                    className={cls.nextPost}>
                    <p>Next post</p>
                    <ArrowIcon className={cls.nextArrow} />
                </div>
            </div>

            <div className={cls.posts}>
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/marketing")
                    }
                    image={marketingimage}
                    title={
                        "Boost Marketing with Data Holder's Data Management"
                    }
                    description={
                        "Product metrics storage plays a crucial role in a successful business strategy. They enable companies to track and analyze.."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate(
                            "/companyblog/dashboardspost"
                        )
                    }
                    image={dashboardsimage}
                    title={
                        "Boost Productivity with Data Holder Dashboards"
                    }
                    description={
                        "Productivity is a key factor in modern business success, and one of the important tools that contributes to its enhancement.."
                    }
                    date={"October 26, 2023"}
                />
            </div>
        </div>
    )
}
