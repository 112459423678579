import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"

import cls from "./textfield.module.scss"

export const TextField = ({
    label,
    placeholder,
    description,
    value,
    setValue,
    className,
    type,
    pattern,
    valid = true
}) => {
    return (
        <div
            className={classNames(cls.TextField, [
                className,
            ], {
                [cls.invalid]: !valid
            })}>
            <h4>{label}</h4>
            <input
                type={type}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={placeholder}
                pattern={pattern}
            />
            <p className={cls.description}>{description}</p>
        </div>
    )
}
