import React from "react"
import ReactDOM from "react-dom/client"
import "@/app/index.scss"
import App from "@/app/App"
import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom"
import { NotFoundPage } from "./pages/NotFoundPage"
import { PolicyLeagcy } from "./pages/PolicyLegacy/PolicyLeagcy"
import { AboutPage } from "./pages/AboutPage/AboutPage"
import "./i18n.js"
import { Terms } from "./pages/Terms/Terms"
import { CompanyBlogLayout } from "./pages/CompanyBlogLayout/CompanyBlogLayout"

import { BlogPostMarketing } from "./pages/CompanyBlogLayout/BlogPostMarketing/BlogPostMarketing"
import { CompanyBlog } from "./pages/CompanyBlogLayout/ComapnyBlogList/CompanyBlog"
import { BlogPostBigData } from "./pages/CompanyBlogLayout/BlogPostBigData/BlogPostBigData"
import { BlogPostDasboards } from "./pages/CompanyBlogLayout/BlogPostDashboards/BlogPostDashboards"
import { BlogPostMaster } from "./pages/CompanyBlogLayout/BlogPostMaster/BlogPostMaster"
import { BlogPostMobile } from "./pages/CompanyBlogLayout/BlogPostMobile/BlogPostMobile"

const router = createBrowserRouter([
    {
        path: "*",
        element: <NotFoundPage />,
    },
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/currentlyUnavailable",
        element: <NotFoundPage />,
    },
    {
        path: "/policy_legacy",
        element: <PolicyLeagcy />,
    },
    {
        path: "/about",
        element: <AboutPage />,
    },
    {
        path: "/termsofservice",
        element: <Terms />,
    },
    {
        path: "/companyblog",
        element: <CompanyBlogLayout />,
        children: [
            {
                path: "",
                element: <CompanyBlog />, // This will render the same component as the root path, but with the slug parameter
            },
            {
                path: "marketing",
                element: <BlogPostMarketing />, // This will render the BlogPostMarketing component with the slug parameter
            },
            {
                path: "bigdata",
                element: <BlogPostBigData />,
            },
            {
                path: "dashboardspost",
                element: <BlogPostDasboards />,
            },
            {
                path: "masterpost",
                element: <BlogPostMaster />,
            },
            {
                path: "mobilepost",
                element: <BlogPostMobile />,
            },
        ],
    },
])

const root = ReactDOM.createRoot(
    document.getElementById("root")
)
root.render(<RouterProvider router={router} />)
