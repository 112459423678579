import "./App.scss"
import HomeBlock from "@/widgets/HomeBlock/HomeBlock"
import Header from "@/widgets/Header/Header"
import AboutBlock from "@/widgets/AboutBlock/AboutBlock"
import PricingBlock from "@/widgets/Pricing/PricingBlock"
import background from "@/shared/assets/images/BG.webp"
import minBackground from "@/shared/assets/images/minBG.webp"
import footerBG from "@/shared/assets/images/FooterBG.webp"
import BlogBlock from "@/widgets/BlogBlock/BlogBlock"
import FarewellBlock from "@/widgets/FarewellBlock/FarewellBlock"
import Footer from "@/widgets/Footer/Footer"
import CookieNotice from "@/widgets/CookieNotice/CookieNotice"
import { useEffect } from "react"
import useProgressiveImg from "@/shared/lib/hooks/useProgressiveImage"

function App() {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    const BlurredUpImage = () => {
        const [src, { blur }] = useProgressiveImg(
            minBackground,
            background
        )
        return (
            <img
                fetchpriority='high'
                className='App-backgroundImage'
                src={src}
            />
        )
    }
    return (
        <div className='App'>
            <div className='app-inner-container'>
                <Header />
                <HomeBlock id={"Home"} />
                <AboutBlock id={"About"} />
                <PricingBlock id={"Pricing"} />
                <BlogBlock id={"UseCases"} />
                <FarewellBlock id={"Documentation"} />
                <Footer />
                <CookieNotice />
            </div>
            {BlurredUpImage()}
            <img
                src={footerBG}
                alt='bg'
                className='App-footer-backgroundImage'
            />
        </div>
    )
}

export default App
