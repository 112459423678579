import React from "react"
import Header from "@/widgets/Header/Header"
import background from "@/shared/assets/images/BG.webp"
import cls from "./terms.module.scss"
import Footer from "@/widgets/Footer/Footer"

export const Terms = () => {
    return (
        <div className={cls.PolicyLegacy}>
            <div className={cls.container}>
                <Header />
                <h1 className={cls.title}>Terms</h1>
                <div className={cls.textContainer}>
                    <p className={cls.version}>
                        Version 1.0, last updated: August
                        10, 2024
                    </p>

                    <p>
                        The following terms and conditions,
                        as well as any future modifications
                        (the “Terms”) are a contract between
                        you or your company (“you” or
                        “your”) and Softner Solutions,
                        s.r.o. (“we,” “us,” or “our”)
                        governing your use, and Softner
                        Solutions, s.r.o. provision, of the
                        Services (as defined below). By
                        using Softner Solutions, s.r.o.
                        software applications, application
                        programming interfaces, Softner
                        Solutions, s.r.o. Webhooks,
                        communication services, networks,
                        and websites, including, but not
                        limited to dataholder.io
                        (collectively, the “Services”), you
                        acknowledge that you have read,
                        understood, accept and agree to be
                        bound by the most recent version of
                        these Terms.
                    </p>

                    <p>
                        You may review the most current
                        version of the Terms at any time on
                        the Softner Solutions, s.r.o.
                        website at{" "}
                        <a
                            href='https://dataholder.io/termsofservice'
                            target='_blank'>
                            dataholder.io/termsofservice
                        </a>{" "}
                        (“Term Page”). Softner Solutions,
                        s.r.o. reserves the right to update
                        and change the Terms from time to
                        time without notice, effective
                        immediately, by posting the updated
                        Terms on the Term Page. Your
                        continued use of the Services after
                        the revision date of any such
                        changes shall constitute consent to
                        such changes. You agree to review
                        the Terms periodically to be aware
                        of any such revisions. Any new
                        features that augment or enhance the
                        current Services, including the
                        release of new tools and resources,
                        shall be subject to the Terms.
                    </p>

                    <p>
                        If you elect to enable additional
                        functionality or features made
                        available through the Services
                        (“Additional Features”), you may be
                        presented with additional terms
                        related to the use of such
                        Additional Features (the “Additional
                        Terms”). By using the Additional
                        Features, you agree and accept the
                        Additional Terms. In the event of a
                        conflict between these Terms and the
                        Additional Terms, the Additional
                        Terms shall govern.
                    </p>

                    <p>
                        <strong>
                            IF YOU DO NOT AGREE TO THESE
                            TERMS, DO NOT USE, OR CEASE YOUR
                            USE OF, THE SERVICES.
                        </strong>
                    </p>

                    <h2>Grant of Limited License</h2>
                    <p>
                        Subject to your compliance with the
                        Terms including, without limitation,
                        maintaining your registration with
                        current and accurate information and
                        the prompt payment of applicable
                        fees, Softner Solutions, s.r.o.
                        hereby grants you a non-exclusive,
                        non-transferable, revocable,
                        worldwide license to access and use
                        the Services to communicate with
                        your end users (“End Users”). All
                        rights not expressly granted to you
                        are reserved by Softner Solutions,
                        s.r.o. and its licensors.
                    </p>

                    <p>
                        Without explicit permission from
                        Softner Solutions, s.r.o., you may
                        not:
                    </p>
                    <ul>
                        <li>
                            use Softner Solutions, s.r.o.
                            trademarks or service marks;
                        </li>
                        <li>
                            alter, modify, obscure, or
                            remove Softner Solutions, s.r.o.
                            trademarks from the Services;
                        </li>
                        <li>
                            alter, modify, or obscure the
                            Services;
                        </li>
                        <li>
                            either explicitly, implicitly,
                            or through acts of omission,
                            represent yourself as acting on
                            behalf of Softner Solutions,
                            s.r.o.; or
                        </li>
                        <li>
                            either explicitly, implicitly,
                            or through acts of omission,
                            represent yourself as a partner,
                            affiliate, reseller, employee,
                            contractor, vendor, advisor, or
                            counsel of Softner Solutions,
                            s.r.o.
                        </li>
                    </ul>

                    <h2>End Users</h2>
                    <p>
                        Access to the Services by an End
                        User shall be governed by your End
                        User terms of service (“Your
                        Terms”), provided that you shall be
                        responsible for ensuring that, as
                        between an End User, you and Softner
                        Solutions, s.r.o.: (i) Softner
                        Solutions, s.r.o. will at all times
                        retain ownership of all of its
                        intellectual property (as further
                        described in the Section entitled
                        “Softner Solutions, s.r.o.
                        Intellectual Property Rights” below)
                        and End Users are granted no rights
                        to such intellectual property, (ii)
                        Softner Solutions, s.r.o. makes no
                        direct or implied warranties to End
                        Users, (iii) you shall not make any
                        representation or warranties to End
                        Users with respect to the Service,
                        other than the representation that
                        you have the necessary rights to
                        allow End Users to use the Service,
                        (iv) Softner Solutions, s.r.o. is
                        the direct and intended beneficiary
                        of Your Terms, and (v) Softner
                        Solutions, s.r.o. will not be liable
                        in any way to End Users, either
                        directly or indirectly. As between
                        Softner Solutions, s.r.o. and you,
                        you are responsible for ensuring
                        that End Users do not communicate
                        information in violation of law
                        using the Service, and for advising
                        them against transmitting sensitive
                        information using the Service,
                        including but not limited to
                        health/medical information or
                        personally identifiable information
                        of minors.
                    </p>

                    <h2>Fees</h2>
                    <p>
                        Softner Solutions, s.r.o. reserves
                        the right to charge fees for your
                        use of the Services, as described
                        during the account registration
                        process or when making changes to
                        your account plan (“Fees”). You
                        agree to pay such Fees in accordance
                        with the terms set forth in such
                        account registration or change to
                        account plan.
                    </p>

                    <p>
                        All Fees are exclusive of all taxes,
                        levies, or duties imposed by taxing
                        authorities, and you shall be
                        responsible for payment of all such
                        taxes, levies, or duties associated
                        with your use of the Services, other
                        than on Softner Solutions, s.r.o.
                        income. You agree to pay for any
                        such taxes that might be applicable
                        to your use of the Services and
                        payments made by you herein.
                    </p>

                    <p>
                        Softner Solutions, s.r.o. reserves
                        the right, in Softner Solutions,
                        s.r.o. sole discretion, to change
                        published prices without notice.
                        Furthermore, we reserve the right to
                        change the Fees for the Services
                        tied to your account upon thirty
                        (30) days advance notice of the
                        applicability of such change to the
                        Fees and shall not apply to Fees
                        already charged to your account.
                    </p>

                    <h2>Modifications to the Services</h2>
                    <p>
                        Softner Solutions, s.r.o. reserves
                        the right at any time to modify or
                        discontinue, temporarily or
                        permanently, the Services (or any
                        part thereof) with or without
                        notice. Softner Solutions, s.r.o.
                        shall not be liable to you or to any
                        third party for any modification,
                        price change, suspension or
                        discontinuance of the Services.
                    </p>

                    <h2>Account Restrictions</h2>
                    <p>
                        You must be legally able to enter
                        into these Terms in your
                        jurisdiction, such as being older
                        than the minimum age of consent to
                        enter into contracts.
                    </p>

                    <p>
                        You must be a natural person or a
                        natural person representing a
                        company, association or other
                        organization. Accounts registered by
                        “bots” or other automated methods
                        are in violation of these Terms and
                        will be immediately terminated.
                    </p>

                    <p>
                        You must provide a valid email
                        address and truthfully provide any
                        other information requested in order
                        to complete the signup process
                        (“Registration Data”) and provide
                        prompt updates to the Registration
                        Data in the event of any changes.
                    </p>

                    <p>
                        You must not impersonate nor
                        misrepresent your identity or
                        affiliations with other persons or
                        entities.
                    </p>

                    <p>
                        You are responsible for maintaining
                        the security of your account and
                        password. Softner Solutions, s.r.o.
                        shall not be liable for any loss or
                        damage from your failure to comply
                        with this security obligation. You
                        are liable for any acts or omissions
                        made using your login and password.
                    </p>

                    <p>
                        You must not intentionally and
                        maliciously disrupt the reasonable
                        enjoyment of others using the
                        Service.
                    </p>

                    <p>
                        If your use of the Services causes
                        undue stress on the Services and the
                        systems running the Services,
                        Softner Solutions, s.r.o. reserves
                        the right to limit or disable your
                        access to the Services without
                        notice.
                    </p>

                    <p>
                        Verbal, physical, written or other
                        abuse (including threats of abuse or
                        retribution) of any customer,
                        employee, member, or officer of
                        Softner Solutions, s.r.o. will
                        result in immediate account
                        termination.
                    </p>

                    <p>
                        You may not forge or manipulate
                        headers, data, or network protocols
                        to disguise the origin of Content or
                        otherwise disrupt the intended use
                        of the Services.
                    </p>

                    <h2>Content Restrictions</h2>
                    <p>
                        You are responsible for all
                        materials, including without
                        limitation, information, data, text,
                        software, music, sound, photographs,
                        graphics, video, and email messages
                        or other kinds of messages posted,
                        received or sent through the
                        Services (collectively “Content”)
                        and for all activity that occurs
                        under your account, whether done so
                        by you or any End User of the
                        Service who is chatting with you.
                    </p>
                    <p>
                        We have the right, but not the
                        obligation, to remove Content that
                        we determine in our sole discretion
                        to be “Offensive Content” as defined
                        below. We also have the right, but
                        not the obligation, to limit or
                        revoke the use privileges of anyone
                        who posts such Content or engages in
                        unacceptable behavior on or through
                        the Services.
                    </p>
                    <p>
                        Softner Solutions, s.r.o. does not
                        warrant that the Content will be
                        accessible or transferable on the
                        Services either for short term or
                        long term use. To the extent any
                        Content is available for use through
                        the Services, you agree to only use
                        such Content for internal purposes
                        only to address the End User’s
                        needs.
                    </p>
                    <p>
                        We cannot control all Content posted
                        by third parties to the Services.
                        You agree to use the Services at
                        your own risk. You understand that
                        by using the Services you may be
                        exposed to Content that you may find
                        offensive, indecent, incorrect or
                        objectionable, and you agree that
                        under no circumstances will Softner
                        Solutions, s.r.o. be liable in any
                        way for any Content, including any
                        errors or omissions in any Content,
                        or any loss or damage of any kind
                        incurred as a result of your use of
                        any Content. You understand that you
                        must evaluate and bear all risks
                        associated with the use of any
                        Content, including any reliance on
                        the Content, integrity, and accuracy
                        of such Content.
                    </p>
                    <p>
                        You may not post any unlawful,
                        offensive, threatening, libelous,
                        defamatory, obscene or otherwise
                        objectionable Content, or Content
                        that violates any of these Terms
                        (“Offensive Content”). Examples of
                        such Offensive Content include, but
                        are not limited to:
                    </p>
                    <ul>
                        <li>
                            Abuse, harassment, threats,
                            flaming or intimidation of any
                            person or organization
                        </li>
                        <li>
                            Engaging in or contributing to
                            any illegal activity or activity
                            that violates others’ rights
                        </li>
                        <li>
                            Use of derogatory,
                            discriminatory or excessively
                            graphic language
                        </li>
                        <li>
                            Providing information that is
                            false, misleading or inaccurate
                        </li>
                        <li>
                            Hacking or modifying the
                            Services to falsely imply an
                            association with Softner
                            Solutions, s.r.o.
                        </li>
                        <li>
                            Engaging in or contributing to
                            any activity that maliciously
                            degrades the Services
                        </li>
                        <li>
                            Implying or pretending to be
                            affiliated with a company or
                            organization with which you are
                            not affiliated, or
                            misrepresenting the extent of
                            your affiliation or role with an
                            affiliated company or
                            organization
                        </li>
                        <li>
                            Disclosing personal login
                            information, including, but not
                            limited to passwords without the
                            express permission of the
                            individual or organization who
                            provided it to you
                        </li>
                        <li>
                            Transmitting worms, viruses or
                            harmful software
                        </li>
                        <li>
                            Sending spam, unsolicited mail,
                            junk mail or chain letters
                        </li>
                        <li>
                            Disclosing personal or
                            proprietary information of
                            another user, End User, person
                            or organization
                        </li>
                        <li>
                            Altering the Service’s user
                            interface in a way that removes
                            access to any part of the
                            Service’s functionality and/or
                            inhibits access to or the
                            display of advertisements
                        </li>
                        <li>
                            Collecting the content or
                            information belonging to another
                            user, an End User or Softner
                            Solutions, s.r.o., or otherwise
                            accessing the Services, using
                            automated means (such as
                            harvesting bots, robots, spiders
                            or scrapers) without our
                            permission
                        </li>
                        <li>
                            Soliciting log in information or
                            accessing the account of another
                            user or End User of the Services
                        </li>
                        <li>
                            Promoting, condoning, endorsing,
                            distributing, publishing, or
                            posting any material that
                            solicits money, funds or people
                            on websites or other properties
                            that belong to Softner
                            Solutions, s.r.o.
                        </li>
                        <li>
                            Aiding in the use of the
                            Services for purposes of
                            advertisement or solicitation
                            for commercial goods or services
                            on websites or other properties
                            that belong to Softner
                            Solutions, s.r.o.
                        </li>
                        <li>
                            Harassing, annoying,
                            intimidating or threatening
                            users or End Users of the
                            Services or employees or agents
                            of Softner Solutions, s.r.o.
                        </li>
                        <li>
                            Aiding or engaging in deceptive
                            or predatory practices towards
                            users or End Users of the
                            Service
                        </li>
                        <li>
                            Removing or altering, visually
                            or not, any copyrights,
                            trademarks, or proprietary marks
                            and rights owned by us
                        </li>
                        <li>
                            Forging, manipulating or
                            plagiarizing messages,
                            communications, headers or
                            identifiers to imply an
                            association with us or the
                            Services
                        </li>
                        <li>
                            Posting, publishing, displaying
                            or transmitting in any way
                            proprietary information,
                            copyrighted material and
                            trademarks not belonging to you,
                            without the prior written
                            consent of the owner of such
                            rights
                        </li>
                        <li>
                            Posting content that advocates,
                            endorses, condones or promotes
                            harassment of others, or content
                            that actively or indirectly
                            harasses others
                        </li>
                        <li>
                            Posting content that is
                            offensive and harmful, including
                            but not limited to, content that
                            advocates, endorses, condones or
                            promotes racism, bigotry, hatred
                            or physical harm of any kind
                            against any individual or any
                            group of individuals
                        </li>
                        <li>
                            Posting content that provides
                            materials or access to materials
                            that exploits people under the
                            age of 18 in an abusive, violent
                            or sexual manner
                        </li>
                        <li>
                            Posting content that solicits
                            personal information or any
                            information from anyone under
                            the age of 18
                        </li>
                        <li>
                            Posting content that uses,
                            promotes or links to an illegal
                            or unauthorized copy of another
                            person’s copyrighted work or
                            intellectual property
                        </li>
                    </ul>
                    <p>
                        Grant of License. You represent that
                        you have the authority to and do
                        hereby grant to Softner Solutions,
                        s.r.o. a non-exclusive, irrevocable
                        license to use the Content made
                        available through the Services to
                        the extent necessary to provide the
                        Services (the “Content License”).
                        Such Content License includes,
                        without limitation, the right to
                        reproduce, compress, transfer, and
                        distribute the Content internally
                        and with third party service
                        providers to the extent necessary to
                        enable transmission of and access to
                        the Content through the Service.
                    </p>

                    <h2>
                        Softner Solutions, s.r.o.
                        Intellectual Property Rights
                    </h2>
                    <p>
                        The Services are protected by
                        copyright, trademark, and other laws
                        of the United States and foreign
                        countries. Except as expressly
                        provided in the Terms, Softner
                        Solutions, s.r.o., its suppliers and
                        licensors own all right, title, and
                        interest in and to the Services,
                        including all associated
                        intellectual property rights. You
                        will not remove, alter, or obscure
                        any copyright, trademark, service
                        mark, or other proprietary rights
                        notices incorporated in or
                        accompanying the Services.
                    </p>
                    <p>
                        Softner Solutions, s.r.o. always
                        appreciates your feedback or other
                        suggestions about Softner Solutions,
                        s.r.o., but you understand that we
                        may use such feedback without any
                        obligation to compensate you for
                        them (just as you have no obligation
                        to offer them). You grant us a
                        non-exclusive, worldwide,
                        royalty-free, sublicensable,
                        transferable, irrevocable, perpetual
                        license to use, modify, adapt,
                        publish, perform, or derive new
                        works from your feedback as we
                        choose.
                    </p>
                    <p>
                        You may not duplicate, copy, reuse,
                        or reverse engineer any portion of
                        the Services, including, but not
                        limited to, the HTML, CSS,
                        Javascript, visual design elements,
                        features, functions, or graphics
                        without express written permission
                        from us.
                    </p>
                    <p>
                        All trademarks, service marks,
                        logos, trade names and any other
                        proprietary designations of Softner
                        Solutions, s.r.o. used herein are
                        trademarks or registered trademarks
                        of Softner Solutions, s.r.o. Any
                        other trademarks, service marks,
                        logos, trade names and any other
                        proprietary designations are the
                        trademarks or registered trademarks
                        of their respective parties.
                    </p>

                    <h2>Third-party services</h2>
                    <p>
                        As part of your enjoyment of the
                        Services, subject to these Terms,
                        you may grant a third party access
                        to your account to provide
                        additional products, services, or
                        labor provided you agree to be
                        responsible for the actions of the
                        third party and to bind the third
                        party to these Terms as your agent.
                    </p>
                    <p>
                        From time to time, separate
                        applications or services that
                        integrate or interoperate with the
                        Softner Solutions, s.r.o. Services
                        (collectively, “Add-ons”) may become
                        available, either directly from
                        Softner Solutions, s.r.o. or
                        independently. If you choose to
                        install, access, or enable an
                        Add-on, you agree that the
                        third-party Add-on provider may
                        acquire access to your account data
                        and information as required for the
                        interoperation or integration of
                        such Add-on and you grant Softner
                        Solutions, s.r.o. permission to give
                        such Add-on access to your account
                        data and information. Accordingly,
                        such applications are governed by
                        their own terms and conditions and
                        are not considered Services under
                        these Terms.
                    </p>

                    <h2>Privacy Policy</h2>
                    <p>
                        We take your privacy seriously. You
                        can view our full explanation of how
                        we handle your privacy on our
                        dedicated privacy policy page (the
                        “Privacy policy”).
                    </p>

                    <h2>100% Service Guarantee</h2>
                    <p>
                        We will respond to your support
                        inquiries within one business day
                        and we will ensure the Services have
                        no unexpected downtime.
                    </p>

                    <h2>Cancellation</h2>
                    <p>
                        You are solely responsible for
                        properly cancelling your paid
                        subscription. An email, phone, chat,
                        or written request to cancel your
                        paid subscription is not considered
                        cancellation. You may cancel your
                        paid subscription at any time by
                        clicking on the “billing” link on
                        the dashboard when logged in. The
                        billing page provides a link to
                        cancel your account. The
                        cancellation of your paid
                        subscription will occur at the end
                        of your current paid up period. Upon
                        cancellation or otherwise, you may
                        request that your data be destroyed.
                        The destruction of your data will
                        follow the terms of our Privacy
                        Policy.
                    </p>

                    <h2>Termination</h2>
                    <p>
                        Softner Solutions, s.r.o. may
                        terminate your account without
                        notice in the event that you breach
                        any of these Terms. Your
                        cancellation will take effect
                        immediately and you will not be
                        charged again. In the event of any
                        termination, Softner Solutions,
                        s.r.o. will not refund any of the
                        prepaid Fees. Such termination of
                        the Services will result in the
                        deactivation or deletion of your
                        account or your access to your
                        account, and the forfeiture and
                        relinquishment of all content in
                        your account. Softner Solutions,
                        s.r.o. reserves the right to refuse
                        service to anyone for any reason at
                        any time.
                    </p>

                    <h2>Refunds</h2>
                    <p>
                        You are expected to fulfill your
                        payment obligations. In the case of
                        recurring charges, authorized third
                        parties may store and process your
                        payment information as agreed. Be
                        aware that additional fees and taxes
                        may apply to your transactions.
                    </p>
                    <p>
                        Softner Solutions, s.r.o. is not
                        obligated to provide refunds or
                        credits for partial months or years
                        of the Services, upgrades or
                        downgrades, or unused time during
                        your subscription.
                    </p>

                    <h2>Warranty Disclaimers</h2>
                    <p>
                        You expressly understand and agree
                        that:
                    </p>
                    <p>
                        Softner Solutions, s.r.o. warrants
                        that your data will be treated in
                        accordance with its Privacy Policy,
                        as described in the Privacy Policy.
                        Except as expressly warranted in the
                        terms, all Services are provided “as
                        is” and Softner Solutions, s.r.o.
                        hereby disclaims all warranties and
                        conditions with regard to the
                        Services, whether express, implied,
                        or statutory, including all
                        warranties and conditions of
                        merchantability, fitness for a
                        particular purpose, title, and
                        non-infringement.
                    </p>
                    <p>
                        Other than as expressly set in these
                        Terms, Softner Solutions, s.r.o.
                        makes no warranty that (i) the
                        Services will meet your requirements
                        or expectations, (ii) that your
                        access to or use of the Services
                        will be uninterrupted, timely,
                        secure, or error-free, (iii) that
                        any defects in the Services will be
                        corrected, or (iv) that the Services
                        or any server through which you
                        access the Services are free of
                        viruses or other harmful components.
                    </p>
                    <p>
                        Any material downloaded or otherwise
                        obtained through the use of the
                        Services is accessed at your own
                        discretion and risk, and you will be
                        solely responsible for any damage to
                        your computer system or loss of data
                        that results from the download of
                        any such material.
                    </p>
                    <p>
                        No advice or information, whether
                        oral or written, obtained by you
                        from Softner Solutions, s.r.o. or
                        through or from the Services shall
                        create any warranty not expressly
                        stated in these terms.
                    </p>

                    <h2>Limitation of Liability</h2>
                    <p>
                        You expressly understand and agree
                        that Softner Solutions, s.r.o. and
                        its subsidiaries, affiliates,
                        officers, employees, agents,
                        partners, and licensors shall not be
                        liable to you for any indirect,
                        incidental, special, consequential,
                        or exemplary damages, including, but
                        not limited to, damages for loss of
                        profits, goodwill, use, data, or
                        other intangible losses (even if
                        Softner Solutions, s.r.o. has been
                        advised of the possibility of such
                        damages) in any way relating to or
                        arising from this agreement.
                    </p>

                    <h2>Indemnification</h2>
                    <p>
                        You agree to defend, indemnify and
                        hold harmless Softner Solutions,
                        s.r.o., its officers, directors,
                        employees, and agents, harmless for
                        any losses, costs, liabilities and
                        expenses (including reasonable
                        attorneys’ fees) relating to or
                        arising out of your connection to or
                        use of the Services, including but
                        not limited to any breach by you of
                        these Terms, any claims arising from
                        the content you submit, post,
                        transmit or make available through
                        the Services, and any misuse of the
                        Services or claims against Softner
                        Solutions, s.r.o. by an End User.
                    </p>

                    <h2>Non-solicitation</h2>
                    <p>
                        You may not use the Services to
                        solicit, interfere with, or
                        endeavour to entice away from
                        Softner Solutions, s.r.o. any
                        Softner Solutions, s.r.o. customer,
                        user, or subscriber.
                    </p>
                    <p>
                        The Services are for your own use
                        only. You may not resell, lease or
                        provide them in any other way to
                        anyone else, except as expressly
                        permitted through the Services or by
                        written permission of Softner
                        Solutions, s.r.o. or by acting as an
                        agent contracted by a third party to
                        act on its behalf.
                    </p>

                    <h2>Force Majeure</h2>
                    <p>
                        Softner Solutions, s.r.o. shall not
                        be responsible for any failure to
                        perform due to unforeseen,
                        non-commercial circumstances beyond
                        its reasonable control, including
                        but not limited to acts of God, war,
                        riot, crime, terrorist attacks,
                        embargoes, acts of civil or military
                        authorities, widespread Internet
                        outages, fire, floods, earthquakes,
                        accidents, strikes, fuel or energy.
                        In the event of any such delay, any
                        applicable period of time for action
                        by Softner Solutions, s.r.o. may be
                        deferred for a period equal to the
                        time of such delay.
                    </p>

                    <h2>Integration and Severability</h2>
                    <p>
                        These Terms are the entire agreement
                        between you and Softner Solutions,
                        s.r.o. with respect to the Services
                        and supersede all prior or
                        contemporaneous communications and
                        proposals (whether oral, written or
                        electronic) between you and Softner
                        Solutions, s.r.o. with respect to
                        the Services. If any provision of
                        the Terms is found to be
                        unenforceable or invalid, that
                        provision will be limited or
                        eliminated to the minimum extent
                        necessary so that the Terms will
                        otherwise remain in full force and
                        effect and enforceable. The failure
                        of either party to exercise in any
                        respect any right provided for
                        herein shall not be deemed a waiver
                        of any further rights hereunder.
                    </p>
                </div>
            </div>
            <img
                src={background}
                className={cls.bgWave}
            />
            <Footer className={cls.footer} />
        </div>
    )
}
