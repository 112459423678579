import React from "react"

import cls from "./blogcard.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import { useNavigate } from "react-router-dom"

export const BlogCard = ({
    className,
    image,
    title,
    description,
    date,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className={classNames(
                cls.BlogCard,
                [className],
                {
                    [cls.withImage]: image,
                }
            )}>
            <div className={cls.leftSide}>
                <img
                    className={cls.image}
                    src={image}
                    alt={title}
                />
                <div className={cls.dateContainer}>
                    <p>Posted on</p>
                    <p className={cls.date}>{date}</p>
                </div>
            </div>
            <div className={cls.rightSide}>
                <h3 className={cls.title}>{title}</h3>
                <p className={cls.description}>
                    {description}
                </p>
            </div>
        </div>
    )
}
