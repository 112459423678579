import React from "react"
import { ReactComponent as Image } from "./assets/image.svg"
import cls from "./notfound.module.scss"
import { Logo } from "@/shared/ui/Logo/Logo"
import { Link } from "react-router-dom"
import Button from "@/shared/ui/Button/Button"

export const NotFoundPage = () => {
    return (
        <div className={cls.container}>
            <div className={cls.NotFoundPage}>
                <Logo className={cls.logo} color={"black"} />
                <h1>404 Not Found</h1>
                <p>
                    We apologize for the inconvenience, but
                    the page you are trying to
                </p>
                <p>
                    access is currently unavailable. This
                    could be due to maintenance,
                </p>
                <p>updates, or technical difficulties.</p>
                <Image className={cls.image} />
                <a
                    className={cls.homeButton}
                    href='/'>
                    <Button>Go Home</Button>
                </a>
            </div>
        </div>
    )
}
