import React from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./checkbox.module.scss"

export const Checkbox = ({
    children,
    className,
    value,
    setValue,
    id,
}) => {
    return (
        <div
            className={classNames(cls.Checkbox, [
                className,
            ])}>
            <input
                className={cls.input}
                type='checkbox'
                id={id}
                name={id}
                value={value}
                onChange={(e) => setValue(!value)}
            />
            <label
                className={cls.checkBoxLabel}
                htmlFor={id}></label>

            {children}
        </div>
    )
}
