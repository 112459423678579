import React, { useEffect, useState } from "react"

import cls from "./contactform.module.scss"
import Button from "@/shared/ui/Button/Button"
import Modal from "@/shared/ui/Modal/Modal"
import { classNames } from "@/shared/lib/utils/classNames"
import axios from "axios"
import { TextField } from "@/shared/ui/TextField/TextField"
import { useCookies } from "react-cookie"

const ContactForm = ({ onClick }) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [emailValid, setEmailValid] = useState(true)

    const [success, setSuccess] = useState(false)

    const onSubmit = () => {
        const response = axios
            .post("https://dataholder.io/api/v1/book", {
                email: email,
                name: name,
                phone: String(phone),
            })
            .then((response) => {
                setSuccess(true)
            })
            .catch((error) => {
                setSuccess(false)
            })
    }
    const isValidEmail = (value) => {
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regEmail.test(value)) {
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }

        setEmail(value)
        setCookie("email", value)
    }

    const nameHandler = (value) => {
        setName(value)
        setCookie("name", value)
    }

    const phoneHandler = (value) => {
        setPhone(value.replace(/\D/g, ""))
        setCookie("phone", value.replace(/\D/g, ""))
    }

    const [cookie, setCookie, removeCookie] = useCookies()

    useEffect(() => {
        if (cookie.accept_cookies) {
            setName(cookie.name ?? "")
            setEmail(cookie.email ?? "")
            setPhone(cookie.phone ?? "")
            setEmailValid(
                cookie.email?.trim() === "" ||
                    cookie?.email === undefined
                    ? true
                    : cookie.email_valid
            )
        }
    })

    const renderResult = (success) => {
        if (success) {
            return (
                <div
                    className={classNames(cls.ContactForm, [
                        cls.success,
                    ])}>
                    <svg
                        width='52'
                        height='52'
                        viewBox='0 0 52 52'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            opacity='0.15'
                            d='M26 3C13.3031 3 3 13.3031 3 26C3 38.6969 13.3031 49 26 49C38.6969 49 49 38.6969 49 26C49 13.3031 38.6969 3 26 3Z'
                            stroke='#0CF18A'
                            stroke-width='6'
                        />
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M26 6C14.96 6 6 14.96 6 26C6 37.04 14.96 46 26 46C37.04 46 46 37.04 46 26C46 14.96 37.04 6 26 6ZM20.58 34.58L13.4 27.4C12.62 26.62 12.62 25.36 13.4 24.58C14.18 23.8 15.44 23.8 16.22 24.58L22 30.34L35.76 16.58C36.54 15.8 37.8 15.8 38.58 16.58C39.36 17.36 39.36 18.62 38.58 19.4L23.4 34.58C22.64 35.36 21.36 35.36 20.58 34.58Z'
                            fill='#0CF18A'
                        />
                    </svg>
                    <h3>Your message has been sent!</h3>
                </div>
            )
        } else {
            return (
                <div
                    className={classNames(cls.ContactForm, [
                        cls.success,
                    ])}>
                    <svg
                        width='52'
                        height='52'
                        viewBox='0 0 52 52'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            opacity='0.15'
                            d='M26 3C13.3031 3 3 13.3031 3 26C3 38.6969 13.3031 49 26 49C38.6969 49 49 38.6969 49 26C49 13.3031 38.6969 3 26 3Z'
                            stroke='#FF0000'
                            strokeWidth='6'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M26 6C14.96 6 6 14.96 6 26C6 37.04 14.96 46 26 46C37.04 46 46 37.04 46 26C46 14.96 37.04 6 26 6Z'
                            fill='#FF0000'
                        />
                        <path
                            d='M17 17L35 35M35 17L17 35'
                            stroke='white'
                            strokeWidth='5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>

                    <h3>Something went wrong!</h3>
                </div>
            )
        }
    }
    return (
        <>
            {!success ? (
                <div className={cls.ContactForm}>
                    <div>
                        <h2>Book a Call!</h2>
                        <p>Fill all fields</p>
                    </div>
                    <div className={cls.personalInfo}>
                        <TextField
                            label='Name*'
                            placeholder={"Your name"}
                            value={name}
                            setValue={nameHandler}
                        />
                        <TextField
                            label='Email*'
                            type={"email"}
                            placeholder={"Your email"}
                            value={email}
                            setValue={isValidEmail}
                            valid={emailValid}
                            description={
                                emailValid
                                    ? "The message will be sent on this mail"
                                    : "invalid email format"
                            }
                        />
                        <TextField
                            label='Phone'
                            placeholder={"Your phone"}
                            value={phone}
                            setValue={phoneHandler}
                            type={"tel"}
                            pattern={"^[ 0-9]+$"}
                        />
                    </div>
                    <Button
                        variant={
                            Boolean(
                                String(name)?.trim() &&
                                    String(email)?.trim() &&
                                    String(phone)?.trim() &&
                                    emailValid
                            )
                                ? "primary"
                                : "disabled"
                        }
                        onClick={onSubmit}
                        className={cls.submitButton}
                        link={false}>
                        Submit
                    </Button>
                </div>
            ) : (
                renderResult(success)
            )}
        </>
    )
}

export default ContactForm
