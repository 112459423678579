import React from "react"
import cls from "./button.module.scss"
import { classNames } from "@/shared/lib/utils/classNames"
import { Link } from "react-router-dom"

const Button = ({
    children,
    onClick,
    className,
    variant = "primary",
    size,
    link = true,
}) => {
    const buttonVariants = {
        primary: "primary",
        secondary: "secondary",
        outlined: "outlined",
        white: "white",
        disabled: "disabled",
    }

    const buttonSizes = {
        small: "small",
        medium: "medium",
        large: "large",
    }
    return (
        <button
            disabled={variant === "disabled"}
            className={classNames(
                cls.Button,
                [
                    className,
                    cls[buttonVariants[variant]],
                    cls[buttonSizes[size]],
                ],
                {
                    [cls.disabled]: variant === "disabled",
                }
            )}
            onClick={onClick}>
            {children}
        </button>
    )
}

export default Button
