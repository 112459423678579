import React from "react"
import { ReactComponent as ArrowIcon } from "@/shared/assets/arrowRightIcon.svg"
import marketingimg from "./assets/mobile.webp"
import { ReactComponent as VMark } from "@/shared/assets/vMark.svg"
import Button from "@/shared/ui/Button/Button"
import { BlogCard } from "@/entities/BlogCard/BlogCard"
import marketingimage from "../BlogPostMarketing/assets/marketing.webp"
import masterimage from "../BlogPostMaster/assets/master.webp"
import cls from "./mobile.module.scss"
import { useNavigate } from "react-router-dom"

export const BlogPostMobile = () => {
    const navigate = useNavigate()
    return (
        <div className={cls.content}>
            <div className={cls.title}>
                <h2>
                    Boost Marketing with Data Holder's Data
                    Management
                </h2>
                <div className={cls.date}>
                    <span>Posted on</span>
                    <span className={cls.num}>
                        October 26, 2023
                    </span>
                </div>
            </div>
            <img
                className={cls.image}
                src={marketingimg}
            />
            <p>
                Product metrics storage plays a crucial role
                in a successful business strategy. They
                enable companies to track and analyze
                product performance, identify strengths and
                weaknesses, and make informed decisions for
                further development. In today's world, where
                data becomes the currency of success, having
                convenient tools for storing and visualizing
                product metrics is essential.
            </p>

            <ul className={cls.list}>
                <h3>
                    The following aspects are highlighted:
                </h3>
                <li>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>Sketch, Figma and Adobe XD</p>
                    </div>
                </li>
                <li>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>Fully Layered</p>
                    </div>
                </li>
                <li>
                    <div className={cls.description}>
                        <VMark className={cls.icon} />
                        <p>Unique & Modern Design</p>
                    </div>
                </li>
            </ul>
            <p>
                With the advancement of mobile technologies,
                web development for mobile devices has
                become an integral part of modern business.
                Mobile-optimized web applications allow for
                a maximally convenient user experience and
                attract a wider audience. In this article.
            </p>
            <a href='/client/register'>
                <Button className={cls.getStarted}>
                    Get Started
                </Button>
            </a>
            <div className={cls.pagination}>
                <div
                    onClick={() =>
                        navigate("/companyblog/masterpost")
                    }
                    className={cls.prevPost}>
                    <ArrowIcon className={cls.prevArrow} />
                    <p>Previous post</p>
                </div>
                <div
                    onClick={() =>
                        navigate("/companyblog/marketing")
                    }
                    className={cls.nextPost}>
                    <p>Next post</p>
                    <ArrowIcon className={cls.nextArrow} />
                </div>
            </div>

            <div className={cls.posts}>
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/masterpost")
                    }
                    title={
                        "Master IT Data Management with Data Holder"
                    }
                    image={masterimage}
                    description={
                        "How you can set personal boundaries to reduce stress and..."
                    }
                    date={"October 26, 2023"}
                />
                <BlogCard
                    onClick={() =>
                        navigate("/companyblog/marketing")
                    }
                    image={marketingimage}
                    title={
                        "Boost Marketing with Data Holder's Data Management"
                    }
                    description={
                        "Product metrics storage plays a crucial role in a successful business strategy. They enable companies to track and analyze.."
                    }
                    date={"October 26, 2023"}
                />
            </div>
        </div>
    )
}
